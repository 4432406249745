/* eslint-disable */
import getStore from "Util/Store"
import { fetchQuery } from "Util/Request/Query"
import { fetchMutation } from "Util/Request/Mutation"
import StoreLocatorQuery from "Query/StoreLocator.query"
import PurchaseTypeQuery from "Query/PurchaseType.query"
import IoCheckoutQuery from "Query/IoCheckout.query"
import MyAccountQuery from "SourceQuery/MyAccount.query"
import CityPricingQuery from "Query/CityPricing.query"
import { isSignedIn } from "Util/Auth/IsSignedIn"
import { removeAllCacheStorage } from "Util/Cache/Cache"
import intlTelInput from "intl-tel-input"
import "intl-tel-input/build/css/intlTelInput.css"

var ioCheckoutElement = null
var ioCheckoutStore = null
var addLoadingGlobal
var removeLoadingGlobal
var setIsPlaceOrderEnableGlobal
var cart = {}
var debounsPurchaseTypes
var shippingAddressFields = []
var billingAddressFields = []
var renderComponentFields
var saveAddressesFunc
var debounsSaveAddress
const SHIPPING_ADDRESS_CODE = "ShippingAddress"
const BILLING_ADDRESS_CODE = "BillingAddress"
var collectionOfRegions = []
var collectionOfCities = []
var intlTelInputObject = {}
var storeLocatorList = []
var selectedStoreLocatorId = 0

// Custom Shipping Method
var CUSTOM_SHIPPING_METHOD_CODE_MPSTOREPICKUP = "mpstorepickup"
var CUSTOM_SHIPPING_METHOD_CODE_FLATE_RATE = "flatrate"
var CUSTOM_SHIPPING_METHOD_CODE_FREESHIPPING = "freeshipping"
var CUSTOM_SHIPPING_METHOD_CODE_EXPRESS_HOME_DELIVERY = "expresshomedelivery"

// Custom Payment Method
var CUSTOM_PAYMENT_METHOD_CODE_STOREPAYMENT = "storepayment"
var CUSTOM_PAYMENT_METHOD_CODE_DPO = "dpo"
var CUSTOM_PAYMENT_METHOD_CODE_DPO_LAY_BY = "dpolayby"
var CUSTOM_PAYMENT_METHOD_CODE_CASH_ON_DELIVERY = "cashondelivery"

var selectedPaymentMethod = null

var isNewAddressFields = false
var initialElement = false
var paymentMethodInstrction = {}
var storePaymentRefId = ""

// Zampay payment
var CUSTOM_PAYMENT_METHOD_CODE_ZAMPAY = "zampay"
var zampayPhoneNumberElement = null
var phoneNumberForZampayPayment = null
var dialCodeForZampayPayment = null

// Airtel payment
var CUSTOM_PAYMENT_METHOD_CODE_AIRTEL = "airtel"
var airtelPhoneNumberElement = null
var phoneNumberForAirtelPayment = null
var dialCodeForAirtelPayment = null

// Mtn payment
var CUSTOM_PAYMENT_METHOD_CODE_MTN = "mtn"
var mtnPhoneNumberElement = null
var phoneNumberForMtnPayment = null
var dialCodeForMtnPayment = null

const ORDER_DETAILS = "orderDetails"
const PAYMEWNT_METHOD_CODE = "paymentMethodCode"

export const callIoCheckoutHooks = () => {
  window.IoCheckoutHooks = {
    // Setup
    beforeSetupInit: async function () {
      const sameAsShipping = localStorage.getItem("sameAsShipping")
      if (
        sameAsShipping === "true" ||
        sameAsShipping === undefined ||
        sameAsShipping === null
      ) {
        localStorage.setItem("sameAsShipping", false)
        window.location.reload()
        return
      }
      //  await handleHead()
      await getRegionColl()
      await getRegionCityColl()
      await getStorePickupList()
      await getPaymentMethodInstruction()
    },
    renderIoCheckoutJS: function (props) {
      const {
        ioCheckoutElement: element,
        cart: ioCheckoutCart,
        iocheckout,
        addLoading,
        removeLoading,
      } = props
      cart = ioCheckoutCart

      // Redirect to cart page if cart items is empty
      if (cart.items.length === 0) {
        redirectToCartPage()
        return
      }

      ioCheckoutStore = iocheckout
      if (ioCheckoutElement !== null) {
        return
      }
      addLoadingGlobal = addLoading
      removeLoadingGlobal = removeLoading
      ioCheckoutElement = element
      initial()
    },
    beforeRenderGuestEmail: function () {
      if (isLoginCustomer()) {
        return ""
      }
      return renderPurchaseTypeContainer()
    },

    // Address
    beforeRenderShippingAddress: function () {
      if (!isLoginCustomer()) {
        return ""
      }
      return renderPurchaseTypeContainer()
    },
    renderInitAddressFields: async function (props) {
      const {
        fields,
        renderComponentFields: renderFields,
        showNewAddressBtnOnBillingAddress,
        isNewAddress,
        isEditAddress,
      } = props
      showNewAddressBtnOnBillingAddress(true)
      isNewAddressFields = isNewAddress
      initial(props)

      const addressType = await getAddressType(fields)

      // Fields Assign
      await assignAddressFields(fields, addressType)

      // Render Component Funcation Assign
      renderComponentFields = renderFields

      // Manage Telephone Field
      await handleTelephoneInput(fields, isNewAddress, isEditAddress)

      // Manage City and Region
      const cityFieldInterval = await setInterval(async () => {
        if (collectionOfCities.length > 0) {
          await clearInterval(cityFieldInterval)
          await renderCityAndRegion(fields, isNewAddress, isEditAddress)
        }
      }, 1500)
    },
    renderChangeAddressFields: async function (props) {
      var { changableField, fields, type } = props
      await assignAddressFields(fields, type)
      const isLogin = await isLoginCustomer()
      if (isLogin) {
        if (changableField.id === "region_id") {
          let selectedRegion = await getHeaderSelectedRegionValue()
          await fields.forEach((field, key) => {
            if (field.id === "region_id") {
              selectedRegion = field.value
            }
            fields[key] = field
          })
          let cityOptions = await getCityOptions(selectedRegion)
          await fields.forEach((field, key) => {
            if (field.id === "city") {
              field.value = cityOptions.length > 0 ? cityOptions[0].value : ""
              field.select_options = cityOptions
            }
            fields[key] = field
          })
          //   Update Fields
          await assignAddressFields(fields, type)
          await renderComponentFields(fields, BILLING_ADDRESS_CODE, true)
        }
        return
      }
      if (
        changableField.id === "country_id" ||
        changableField.id === "region_id" ||
        changableField.id === "city"
      ) {
        window.location.reload()
      }
    },
    saveAddressesFields: function (props) {
      const { saveAddresses } = props
      saveAddressesFunc = saveAddresses
    },
    afterRenderTelephone: function () {
      return `<div>
              <div>
                <input class="intlTelInput-phone" type="tel">
              </div>
              <div class="intlTelInput-phone-error-msg">
                Please enter valid phone number.
              </div>
            </div>`
    },
    beforeSaveBillingAddress: function (props) {
      const { billingAddress } = props
      saveShippingAddress(billingAddress)
    },
    afterSaveBillingAddress: function (props) {
      handleSelectedCityLoginCustomerCity()
    },
    afterSaveShippingAddress: function (props) {
      handleSelectedCityLoginCustomerCity()
      setPurchaseTypesInfoToCartDebouns()
    },
    // Shipping Method
    renderCustomShippingMethods: function (props) {
      const { cart, renderMethods } = props
      let methods = []
      if (cart.estimated_shipping_methods.length > 0) {
        const config = getConfig()
        cart.estimated_shipping_methods.forEach((element) => {
          if (
            element.carrier_code ===
              CUSTOM_SHIPPING_METHOD_CODE_MPSTOREPICKUP &&
            storeLocatorList.length > 0
          ) {
            methods.push({
              method_code: element.method_code,
              carrier_code: element.carrier_code,
              defaultSelect: true,
              isHandleDefault: true,
              amount: element.amount.value,
              logo: `${config.base_url}media/wysiwyg/store-pickup.png`,
            })
          }
          if (
            element.carrier_code ===
            CUSTOM_SHIPPING_METHOD_CODE_EXPRESS_HOME_DELIVERY
          ) {
            methods.push({
              method_code: element.method_code,
              carrier_code: element.carrier_code,
              isHandleDefault: true,
              amount: element.amount.value,
              logo: `${config.base_url}media/wysiwyg/asap.png`,
            })
          }
          if (element.carrier_code === CUSTOM_SHIPPING_METHOD_CODE_FLATE_RATE) {
            methods.push({
              method_code: element.method_code,
              carrier_code: element.carrier_code,
              logo: `${config.base_url}media/wysiwyg/flat-rate.png`,
            })
          }
          if (
            element.carrier_code === CUSTOM_SHIPPING_METHOD_CODE_FREESHIPPING
          ) {
            methods.push({
              method_code: element.method_code,
              carrier_code: element.carrier_code,
              logo: `${config.base_url}media/wysiwyg/home-delivery.png`,
            })
          }
        })
      }
      renderMethods(methods)
    },
    afterClickShippingMethodHook: async function (props) {
      handleCustomEventShippingMethod()
    },
    afterRenderMpstorepickupMpstorepickup: function () {
      const cart = getCart()
      if (
        cart &&
        cart.shipping_addresses &&
        cart.shipping_addresses.length > 0 &&
        cart.shipping_addresses[0].selected_shipping_method !== null &&
        cart.shipping_addresses[0].selected_shipping_method.carrier_code ===
          CUSTOM_SHIPPING_METHOD_CODE_MPSTOREPICKUP &&
        cart.shipping_addresses[0].selected_shipping_method.method_code ===
          CUSTOM_SHIPPING_METHOD_CODE_MPSTOREPICKUP
      ) {
        setTimeout(() => {
          renderSelectedStoreLocator()
        }, 2000)
        return (
          `<div>
    		 	<div class="mpstorepickup-title">Collect item from?</div>
    			<select name="stores" id="storesLocator">` +
          storeLocatorList.map((store) => {
            return `<option value="${store.location_id}" >${store.name}</option>`
          }) +
          `</select>
    		  <div class="storesLocator-selected-name"></div>
    		  <div class="storesLocator-selected-address"></div>
    		  </div>`
        )
      }
    },
    afterRenderShippingMethods: function () {
      return `<p>
        Ts &amp; Cs Apply. <a target="_blank" href="${window.location.origin}/asap-delivery">Click here</a> to learn more about our Shipping Methods.
    </p>`
    },
    afterRenderHeader: function () {
      return `<div class="container relative"><div class="back-url">
        <a target="_self" href="${window.location.origin}/cart">Back to cart</a>
    </div></div>`
    },
    // Payment Method
    renderCustomPaymentMethods: function (props) {
      initialElement = false
      const { cart, renderMethods } = props
      let methods = []
      if (cart.available_payment_methods.length > 0) {
        const config = getConfig()

        cart.available_payment_methods.forEach((element) => {
          if (element.code === CUSTOM_PAYMENT_METHOD_CODE_STOREPAYMENT) {
            methods.push({
              method_code: CUSTOM_PAYMENT_METHOD_CODE_STOREPAYMENT,
              logo: `${config.base_url}media/wysiwyg/storepayment-icon.png`,
            })
          }
          if (element.code === CUSTOM_PAYMENT_METHOD_CODE_DPO) {
            methods.push({
              method_code: CUSTOM_PAYMENT_METHOD_CODE_DPO,
              logo: `${config.base_url}media/wysiwyg/visa_mastercard.png`,
            })
          }
          if (element.code === CUSTOM_PAYMENT_METHOD_CODE_DPO_LAY_BY) {
            methods.push({
              method_code: CUSTOM_PAYMENT_METHOD_CODE_DPO_LAY_BY,
              logo: `${config.base_url}media/wysiwyg/layby-icon.png`,
            })
          }
          if (element.code === CUSTOM_PAYMENT_METHOD_CODE_ZAMPAY) {
            methods.push({
              method_code: CUSTOM_PAYMENT_METHOD_CODE_ZAMPAY,
              logo: `${config.base_url}media/wysiwyg/zamtel-icon.png`,
            })
          }
          if (element.code === CUSTOM_PAYMENT_METHOD_CODE_AIRTEL) {
            methods.push({
              method_code: CUSTOM_PAYMENT_METHOD_CODE_AIRTEL,
              logo: `${config.base_url}media/wysiwyg/airtel-money-icon.png`,
            })
          }
          if (element.code === CUSTOM_PAYMENT_METHOD_CODE_MTN) {
            methods.push({
              method_code: CUSTOM_PAYMENT_METHOD_CODE_MTN,
              logo: `${config.base_url}media/wysiwyg/mtn-momo-money-icon.png`,
            })
          }
          if (element.code === CUSTOM_PAYMENT_METHOD_CODE_CASH_ON_DELIVERY) {
            methods.push({
              method_code: CUSTOM_PAYMENT_METHOD_CODE_CASH_ON_DELIVERY,
              logo: `${config.base_url}media/wysiwyg/pay-in-store.png`,
            })
          }
        })
      }
      renderMethods(methods)
      hideInstructionElement("after-render-dpo")
      hideInstructionElement("after-render-dpolayby")
      hideInstructionElement("after-render-airtel")
      hideInstructionElement("after-render-mtn")
      hideInstructionElement("after-render-zampay")
      hideInstructionElement("after-render-storepayment")
    },
    afterClickPaymentMethodHook: async function (props) {
      const {
        paymentMethodCode,
        setIsDefaultPlaceOrder,
        setIsPlaceOrderEnable,
        addLoading,
        removeLoading,
      } = props
      addLoadingGlobal = addLoading
      removeLoadingGlobal = removeLoading
      setIsPlaceOrderEnableGlobal = setIsPlaceOrderEnable
      selectedPaymentMethod = paymentMethodCode
      await localStorage.setItem(PAYMEWNT_METHOD_CODE, selectedPaymentMethod)
      await handleCustomEventPaymentMethod()
      await hideInstructionElement("after-render-dpo")
      await hideInstructionElement("after-render-dpolayby")
      await hideInstructionElement("after-render-airtel")
      await hideInstructionElement("after-render-mtn")
      await hideInstructionElement("after-render-zampay")
      await hideInstructionElement("after-render-storepayment")
      if (paymentMethodCode === CUSTOM_PAYMENT_METHOD_CODE_DPO) {
        setIsDefaultPlaceOrder(false)
        showInstructionElement("after-render-dpo")
      }
      if (paymentMethodCode === CUSTOM_PAYMENT_METHOD_CODE_DPO_LAY_BY) {
        showInstructionElement("after-render-dpolayby")
      }
      if (paymentMethodCode === CUSTOM_PAYMENT_METHOD_CODE_STOREPAYMENT) {
        setIsDefaultPlaceOrder(false)
        setIsPlaceOrderEnable(false)
        getStorePaymentRefNumber()
        showInstructionElement("after-render-storepayment")
      }
      if (paymentMethodCode === CUSTOM_PAYMENT_METHOD_CODE_ZAMPAY) {
        await setIsDefaultPlaceOrder(false)
        await setIsPlaceOrderEnable(false)
        await showInstructionElement("after-render-zampay")
        await loadZampayTelephoneElement()
      }
      if (paymentMethodCode === CUSTOM_PAYMENT_METHOD_CODE_AIRTEL) {
        await setIsDefaultPlaceOrder(false)
        await setIsPlaceOrderEnable(false)
        await showInstructionElement("after-render-airtel")
        await loadAirtelTelephoneElement()
      }
      if (paymentMethodCode === CUSTOM_PAYMENT_METHOD_CODE_MTN) {
        await setIsDefaultPlaceOrder(false)
        await setIsPlaceOrderEnable(false)
        await showInstructionElement("after-render-mtn")
        await loadMtnTelephoneElement()
      }
    },
    afterRenderCashondeliveryInner: function () {
      if (paymentMethodInstrction.cash_on_delivery) {
        return renderInstrcution(paymentMethodInstrction.cash_on_delivery)
      }
    },
    afterRenderDpo: function () {
      if (paymentMethodInstrction.dpo) {
        return renderInstrcution(paymentMethodInstrction.dpo)
      }
    },
    afterRenderDpolayby: function () {
      if (paymentMethodInstrction.dpolayby) {
        return renderInstrcution(paymentMethodInstrction.dpolayby)
      }
    },
    afterRenderAirtel: function () {
      if (paymentMethodInstrction.airtel) {
        return `<div class="airtel-payment-wrapper">
            <div class="airtel-payment-field">
              <div class="airtel-payment-input">
                <input class="intlTelInput-phone-airtel" type="tel">
              </div>
              <div class="intlTelInput-phone-error-msg">
                Please enter valid phone number.
              </div>
            </div>
    			<div class="airtel-payment-decs">${renderInstrcution(
            paymentMethodInstrction.airtel
          )}</div>
    		</div>`
      } else {
        return `<div class="airtel-payment-wrapper">
    			  <div class="airtel-payment-field">
              <div class="airtel-payment-input">
                <input class="intlTelInput-phone-airtel" type="tel">
              </div>
              <div class="intlTelInput-phone-error-msg">
                Please enter valid phone number.
              </div>
            </div>
          </div>`
      }
    },
    afterRenderStorepayment: function () {
      return `<div>
              <div class='storepayment-receipt-reference-id'>
                For the purposes of this order which you are generating for this
                customer, your Reference ID is: <b>${storePaymentRefId}</b>
              </div>
              <div class="storepayment-form">
                Kindly enter the POS Receipt Reference Number on which you have
                received the payment for this order in the field below.
    			<div class="label-top w-full relative p-[1px] z-[1] group floating-label required">
    				<input id="pos_reference_number" name="pos_reference_number" type="text"
    					placeholder="Reference Number"
    					class="input-text w-full p-3 !bg-white border peer placeholder:opacity-0 focus:outline-none focus:ring-0 h-[46px]">
    				<label for="pos_reference_number"
    					class="input-label block absolute translate-y-[-50%] text-lightblack/[0.7] cursor-auto top-[50%] label-width  left-0 peer-focus:left-[6px] pl-3 z-10 transition-all duration-300 peer-focus:top-[4%] peer-focus:text-[80%] peer-focus:bg-white peer-focus:px-[5px] text-ellipsis overflow-hidden truncate max-w-[70%]">Reference Number *
    				</label>
    				<div
    					class="absolute floating-label-border top-0 left-0 z-[-1] peer-focus:w-full peer-focus:h-full bg-primary w-0 h-0">
    				</div>
    				<div class="io-cust-validation text-white font-medium absolute top-[50%] translate-y-[-50%] cursor-pointer z-20 group right-20">
    				<div class="bg-danger p-[5px] text-[80%] absolute min-w-max bottom-[calc(100%+8px)] -right-[7px] after:right-10 hidden group-hover:block group-focus:block after:absolute after:content-[''] after:w-[10px] after:h-[5px] after:top-[100%] after:border-t-danger after:border-t-[5px] after:border-l-[5px] after:border-l-transparent after:border-r-[5px] after:border-r-transparent">Reference number is required.</div>
    				<svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    					<path d="M21.0808 22H2.91967C2.25064 22 1.61339 21.6411 1.26287 21.0212C0.912377 20.4014 0.912377 19.6836 1.26287 19.0637L10.3432 2.97878C10.6937 2.35894 11.299 2 12 2C12.701 2 13.3063 2.35891 13.6568 2.97878L22.7371 19.0637C23.0876 19.6835 23.0876 20.4013 22.7371 21.0212C22.3549 21.6411 21.7496 22 21.0805 22H21.0808ZM12 3.0765C11.6815 3.0765 11.3947 3.23969 11.2673 3.53337L2.18695 19.6182C2.02758 19.8792 2.02758 20.2381 2.18695 20.4993C2.34632 20.7602 2.63311 20.9561 2.91967 20.9561H21.0808C21.3994 20.9561 21.6861 20.7929 21.8136 20.4993C21.9729 20.2383 21.9729 19.8794 21.8136 19.6182L12.7332 3.53337C12.5739 3.23969 12.2871 3.0765 12.0003 3.0765H12Z" fill="#DC2626"></path>
    					<path d="M12.0952 15.8978H11.8722C11.5854 15.8978 11.3623 15.6694 11.3623 15.3757V8.52211C11.3623 8.22844 11.5854 8 11.8722 8H12.0952C12.382 8 12.6051 8.22844 12.6051 8.52211V15.3757C12.6049 15.6694 12.382 15.8978 12.0952 15.8978Z" fill="#DC2626"></path>
    					<path d="M12.1265 18.6714H11.9034C11.6166 18.6714 11.3936 18.443 11.3936 18.1493V17.4967C11.3936 17.203 11.6166 16.9746 11.9034 16.9746H12.1265C12.4133 16.9746 12.6364 17.203 12.6364 17.4967V18.1493C12.6364 18.4432 12.4133 18.6714 12.1265 18.6714Z" fill="#DC2626"></path>
    				</svg>
    				</div>
    			</div>
              </div>
            </div>`
    },
    afterRenderZampay: function () {
      if (paymentMethodInstrction.zampay) {
        return `<div class="zampay-payment-wrapper">
            <div class="zampay-payment-field">
              <div class="zampay-payment-input">
                <input class="intlTelInput-phone-zampay" type="tel">
              </div>
              <div class="intlTelInput-phone-error-msg">
                Please enter valid phone number.
              </div>
            </div>
    			<div class="zampay-payment-decs">${renderInstrcution(
            paymentMethodInstrction.zampay
          )}</div>
    		</div>`
      } else {
        return `<div class="zampay-payment-wrapper">
    			  <div class="zampay-payment-field">
              <div class="zampay-payment-input">
                <input class="intlTelInput-phone-zampay" type="tel">
              </div>
              <div class="intlTelInput-phone-error-msg">
                Please enter valid phone number.
              </div>
            </div>
          </div>`
      }
    },
    afterRenderAirtel: function () {
      if (paymentMethodInstrction.airtel) {
        return `<div class="airtel-payment-wrapper">
            <div class="airtel-payment-field">
              <div class="airtel-payment-input">
                <input class="intlTelInput-phone-airtel" type="tel">
              </div>
              <div class="intlTelInput-phone-error-msg">
                Please enter valid phone number.
              </div>
            </div>
    			<div class="airtel-payment-decs">${renderInstrcution(
            paymentMethodInstrction.airtel
          )}</div>
    		</div>`
      } else {
        return `<div class="airtel-payment-wrapper">
    			  <div class="airtel-payment-field">
              <div class="airtel-payment-input">
                <input class="intlTelInput-phone-airtel" type="tel">
              </div>
              <div class="intlTelInput-phone-error-msg">
                Please enter valid phone number.
              </div>
            </div>
          </div>`
      }
    },
    afterRenderMtn: function () {
      if (paymentMethodInstrction.mtn) {
        return `<div class="mtn-payment-wrapper">
            <div class="mtn-payment-field">
              <div class="mtn-payment-input">
                <input class="intlTelInput-phone-mtn" type="tel">
              </div>
              <div class="intlTelInput-phone-error-msg">
                Please enter valid phone number.
              </div>
            </div>
    			<div class="mtn-payment-decs">${renderInstrcution(
            paymentMethodInstrction.mtn
          )}</div>
    		</div>`
      } else {
        return `<div class="mtn-payment-wrapper">
    			  <div class="mtn-payment-field">
              <div class="mtn-payment-input">
                <input class="intlTelInput-phone-mtn" type="tel">
              </div>
              <div class="intlTelInput-phone-error-msg">
                Please enter valid phone number.
              </div>
            </div>
          </div>`
      }
    },
    beforeRenderDpo: function () {
      return `<div>
    			<form
    				name="dpo_checkout"
    				id="dpo_checkout"
    				action=""
    				method="get"
    			>
    				<input type="hidden" id="dpo-id" name="ID" value="">
    			</form>
    		</div>`
    },
  }
}

const getConfig = () => {
  return getStore().getState().ConfigReducer
}

const initial = async () => {
  if (initialElement) {
    return
  }
  initialElement = true
  hideShippingAddress()

  const purchaseTypeElementInterval = setInterval(async () => {
    const purchaseTypes = ioCheckoutElement.querySelectorAll(
      'input[name="purchaseType"]'
    )
    if (purchaseTypes.length > 0) {
      clearInterval(purchaseTypeElementInterval)
      await getPurchaseTypesInfo()
      await handlePurchaseTypes()
    }
  }, 100)

  await handleCustomEventShippingMethod()
  await handleSelectedCityLoginCustomerCity()
  await handleCustomEventPaymentMethod()
}

//const handleHead = () => {
//  const head = document.head

//  const script = document.createElement("script")
//  script.src =
//    "https://cdn.jsdelivr.net/npm/intl-tel-input@25.3.0/build/js/intlTelInput.min.js"
//  script.async = true
//  head.appendChild(script)
//}

const renderPurchaseTypeContainer = () => {
  return `
    <div class="ioc-purchase-wrapper">
      <div class="ioc-col-title uppercase font-semibold">Purchase Type</div>
      <div id="purchase-type-error-msg" class="text-danger mt-10"></div>
      <div class="mt-20">
          <div class="radio-wrapper mb-20">
              <div class="radio-btn"><input id="purchaseTypePersonal" type="radio" name="purchaseType" value="personal"
                      checked> <label for="purchaseTypePersonal"> Personal </label></div>
              <div class="radio-btn"><input id="purchaseTypeBusiness" type="radio" name="purchaseType" value="business">
                  <label for="purchaseTypeBusiness"> Business / Organization</label>
              </div>
          </div>
          <div class="business-options">
              <div class="business-options-fields">
                <div class="mb-20">
                  <div class="label-top w-full relative p-[1px] z-[1] group floating-label required">
                      <input id="business_tpin_number" name="business_tpin_number" type="text"
                          placeholder="Business TPIN Numbeber"
                          class="input-text w-full p-3 !bg-white border peer placeholder:opacity-0 focus:outline-none focus:ring-0 h-[46px]">

                      <label for="business_tpin_number"
                          class="input-label block absolute translate-y-[-50%] text-lightblack/[0.7] cursor-auto top-[50%] label-width  left-0 peer-focus:left-[6px] pl-3 z-10 transition-all duration-300 peer-focus:top-[4%] peer-focus:text-[80%] peer-focus:bg-white peer-focus:px-[5px] text-ellipsis overflow-hidden truncate max-w-[70%]">Business
                          / Organization TPIN Number <sup class="text-danger">*</sup>
                      </label>
                      <div
                          class="absolute floating-label-border top-0 left-0 z-[-1] peer-focus:w-full peer-focus:h-full bg-primary w-0 h-0">
                      </div>
                      <div class="io-cust-validation text-white font-medium absolute top-[50%] translate-y-[-50%] cursor-pointer z-20 group right-20">
                        <div class="bg-danger p-[5px] text-[80%] absolute min-w-max bottom-[calc(100%+8px)] -right-[7px] after:right-10 hidden group-hover:block group-focus:block after:absolute after:content-[''] after:w-[10px] after:h-[5px] after:top-[100%] after:border-t-danger after:border-t-[5px] after:border-l-[5px] after:border-l-transparent after:border-r-[5px] after:border-r-transparent">Business tip number is required.</div>
                        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M21.0808 22H2.91967C2.25064 22 1.61339 21.6411 1.26287 21.0212C0.912377 20.4014 0.912377 19.6836 1.26287 19.0637L10.3432 2.97878C10.6937 2.35894 11.299 2 12 2C12.701 2 13.3063 2.35891 13.6568 2.97878L22.7371 19.0637C23.0876 19.6835 23.0876 20.4013 22.7371 21.0212C22.3549 21.6411 21.7496 22 21.0805 22H21.0808ZM12 3.0765C11.6815 3.0765 11.3947 3.23969 11.2673 3.53337L2.18695 19.6182C2.02758 19.8792 2.02758 20.2381 2.18695 20.4993C2.34632 20.7602 2.63311 20.9561 2.91967 20.9561H21.0808C21.3994 20.9561 21.6861 20.7929 21.8136 20.4993C21.9729 20.2383 21.9729 19.8794 21.8136 19.6182L12.7332 3.53337C12.5739 3.23969 12.2871 3.0765 12.0003 3.0765H12Z" fill="#DC2626"></path>
                          <path d="M12.0952 15.8978H11.8722C11.5854 15.8978 11.3623 15.6694 11.3623 15.3757V8.52211C11.3623 8.22844 11.5854 8 11.8722 8H12.0952C12.382 8 12.6051 8.22844 12.6051 8.52211V15.3757C12.6049 15.6694 12.382 15.8978 12.0952 15.8978Z" fill="#DC2626"></path>
                          <path d="M12.1265 18.6714H11.9034C11.6166 18.6714 11.3936 18.443 11.3936 18.1493V17.4967C11.3936 17.203 11.6166 16.9746 11.9034 16.9746H12.1265C12.4133 16.9746 12.6364 17.203 12.6364 17.4967V18.1493C12.6364 18.4432 12.4133 18.6714 12.1265 18.6714Z" fill="#DC2626"></path>
                        </svg>
                      </div>
                  </div>
                </div>
                  <div class="mb-20 ">
                    <div class="mb-10">The name in which the Invoice should be raised</div>
                      <div class="label-top w-full relative p-[1px] z-[1] group floating-label required">
                          <input id="business_name" name="business_name" type="text" placeholder="Business Name"
                              class="input-text w-full p-3 !bg-white border peer placeholder:opacity-0 focus:outline-none focus:ring-0 h-[46px]">

                          <label for="business_name"
                              class="input-label block absolute translate-y-[-50%] text-lightblack/[0.7] cursor-auto top-[50%] label-width  left-0 peer-focus:left-[6px] pl-3 z-10 transition-all duration-300 peer-focus:top-[4%] peer-focus:text-[80%] peer-focus:bg-white peer-focus:px-[5px] text-ellipsis overflow-hidden truncate max-w-[70%]">Business / Organization Name <sup class="text-danger">*</sup>
                          </label>
                          <div
                              class="absolute floating-label-border top-0 left-0 z-[-1] peer-focus:w-full peer-focus:h-full bg-primary w-0 h-0">
                          </div>
                          <div class="io-cust-validation text-white font-medium absolute top-[50%] translate-y-[-50%] cursor-pointer z-20 group right-20">
                            <div class="bg-danger p-[5px] text-[80%] absolute min-w-max bottom-[calc(100%+8px)] -right-[7px] after:right-10 hidden group-hover:block group-focus:block after:absolute after:content-[''] after:w-[10px] after:h-[5px] after:top-[100%] after:border-t-danger after:border-t-[5px] after:border-l-[5px] after:border-l-transparent after:border-r-[5px] after:border-r-transparent">Business Name is required.</div>
                            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M21.0808 22H2.91967C2.25064 22 1.61339 21.6411 1.26287 21.0212C0.912377 20.4014 0.912377 19.6836 1.26287 19.0637L10.3432 2.97878C10.6937 2.35894 11.299 2 12 2C12.701 2 13.3063 2.35891 13.6568 2.97878L22.7371 19.0637C23.0876 19.6835 23.0876 20.4013 22.7371 21.0212C22.3549 21.6411 21.7496 22 21.0805 22H21.0808ZM12 3.0765C11.6815 3.0765 11.3947 3.23969 11.2673 3.53337L2.18695 19.6182C2.02758 19.8792 2.02758 20.2381 2.18695 20.4993C2.34632 20.7602 2.63311 20.9561 2.91967 20.9561H21.0808C21.3994 20.9561 21.6861 20.7929 21.8136 20.4993C21.9729 20.2383 21.9729 19.8794 21.8136 19.6182L12.7332 3.53337C12.5739 3.23969 12.2871 3.0765 12.0003 3.0765H12Z" fill="#DC2626"></path>
                              <path d="M12.0952 15.8978H11.8722C11.5854 15.8978 11.3623 15.6694 11.3623 15.3757V8.52211C11.3623 8.22844 11.5854 8 11.8722 8H12.0952C12.382 8 12.6051 8.22844 12.6051 8.52211V15.3757C12.6049 15.6694 12.382 15.8978 12.0952 15.8978Z" fill="#DC2626"></path>
                              <path d="M12.1265 18.6714H11.9034C11.6166 18.6714 11.3936 18.443 11.3936 18.1493V17.4967C11.3936 17.203 11.6166 16.9746 11.9034 16.9746H12.1265C12.4133 16.9746 12.6364 17.203 12.6364 17.4967V18.1493C12.6364 18.4432 12.4133 18.6714 12.1265 18.6714Z" fill="#DC2626"></path>
                            </svg>
                          </div>
                      </div>
                      
                  </div>
              </div>
          </div>
      </div>
  </div>`
  // return `<div>
  //   <div>Purchase Type</div>
  //   <div id="purchase-type-error-msg"></div>
  //   <div>
  //     <input type="radio" name="purchaseType" value="personal" checked> Personal
  //     <input type="radio" name="purchaseType" value="business"> Business / Organization
  //   </div>
  //   <div class="business-options">
  //     <div>
  //       <lable>Business / Organization TPIN Number *</label>
  //       <input type="text" name="business_tpin_number" />
  //     </div>
  //     <div>
  //       <lable>Business / Organization Name *</label>
  //       <input type="text" name="business_name" />
  //     </div>
  //   </div>
  // </div>`;
}

const handlePurchaseTypes = () => {
  // Initial Load
  const checkedPurchaseType = ioCheckoutElement.querySelector(
    'input[name="purchaseType"]:checked'
  )
  if (checkedPurchaseType) {
    purchaseTypeShowOrHide(checkedPurchaseType.value)
  }

  // OnChange Event Handle
  const purchaseTypes = ioCheckoutElement.querySelectorAll(
    'input[name="purchaseType"]'
  )
  if (purchaseTypes.length > 0) {
    purchaseTypes.forEach((purchaseType) => {
      purchaseType.addEventListener("change", async (e) => {
        const selectedPurchaseType = e.target.value
        await handlePurchaseTypesError() // For Validation
        await purchaseTypeShowOrHide(selectedPurchaseType)
        await handlePurchaseTypes()
        await setPurchaseTypesInfoToCartDebouns(false)
      })
    })
  }

  const inputBusinessTpinNumberInterval = setInterval(async () => {
    const inputBusinessTpinNumber = ioCheckoutElement.querySelector(
      'input[name="business_tpin_number"]'
    )
    if (inputBusinessTpinNumber) {
      clearInterval(inputBusinessTpinNumberInterval)
      inputBusinessTpinNumber.addEventListener("input", (e) => {
        handlePurchaseTypesError(inputBusinessTpinNumber)
        setPurchaseTypesInfoToCartDebouns()
      })
    }
  }, 100)

  const inputBusinessNameInterval = setInterval(async () => {
    const inputBusinessName = ioCheckoutElement.querySelector(
      'input[name="business_name"]'
    )
    if (inputBusinessName) {
      clearInterval(inputBusinessNameInterval)
      inputBusinessName.addEventListener("input", (e) => {
        handlePurchaseTypesError(inputBusinessName)
        setPurchaseTypesInfoToCartDebouns()
      })
    }
  }, 100)

  setPurchaseTypesInfoToCartDebouns()
}

const purchaseTypeShowOrHide = (type) => {
  if (type === "personal") {
    ioCheckoutElement.querySelector(".business-options").style.display = "none"
  } else {
    ioCheckoutElement.querySelector(".business-options").style.display = "block"
  }
}

const getPurchaseTypesInfo = async () => {
  try {
    const cart = await getCart()
    const {
      getPurchaseTypeElightwalk: {
        status,
        purchase_type,
        business_name,
        business_tpin_number,
      },
    } = await fetchQuery(PurchaseTypeQuery.getPurchaseType(cart.id))

    if (status) {
      let selectedPurchaseType = purchase_type ?? "personal"
      const allPurchaseTypesElement = ioCheckoutElement.querySelectorAll(
        'input[name="purchaseType"]'
      )
      allPurchaseTypesElement.forEach((purchaseTypeElement) => {
        purchaseTypeElement.checked =
          purchaseTypeElement.value === selectedPurchaseType
      })
      ioCheckoutElement.querySelector(
        'input[name="business_tpin_number"]'
      ).value = business_tpin_number
      ioCheckoutElement.querySelector('input[name="business_name"]').value =
        business_name
      purchaseTypeShowOrHide(selectedPurchaseType)
    }
  } catch (error) {
    handleErrorElement(true, error.message)
  }
}

const setPurchaseTypesInfoToCartDebouns = (isValidation = true) => {
  handleErrorElement(false)
  clearTimeout(debounsPurchaseTypes)
  debounsPurchaseTypes = setTimeout(async () => {
    await addLoadingGlobal()
    await setPurchaseTypesInfoToCart(isValidation)
    await removeLoadingGlobal()
  }, 1000)
}

const setPurchaseTypesInfoToCart = async (isValidation) => {
  try {
    placeOrderBtnEnableDisable()
    const cart = await getCart()
    let businessTpinNumber = ""
    let businessName = ""

    const selectedPurchaseType = ioCheckoutElement.querySelector(
      'input[name="purchaseType"]:checked'
    )

    if (selectedPurchaseType && selectedPurchaseType.value === "business") {
      businessTpinNumber = ioCheckoutElement.querySelector(
        'input[name="business_tpin_number"]'
      ).value
      businessName = ioCheckoutElement.querySelector(
        'input[name="business_name"]'
      ).value

      if (businessTpinNumber === "" || businessName === "") {
        ioCheckoutElement
          .querySelector("#placeOrderButton")
          .setAttribute("disabled", "")
        return
      }
    }

    const {
      savePurchaseTypeElightwalk: { status, message },
    } = await fetchMutation(
      PurchaseTypeQuery.savePurchaseType({
        cart_id: cart.id,
        purchase_type: selectedPurchaseType.value,
        business_name: businessName,
        business_tpin_number: businessTpinNumber,
      })
    )

    if (!status) {
      handleErrorElement(false, message)
    }
  } catch (error) {
    handleErrorElement(true, error.message)
  }
}

const handleErrorElement = (error, msg = "") => {
  if (error) {
    ioCheckoutElement.querySelector("#purchase-type-error-msg").innerText = msg
    ioCheckoutElement.querySelector("#purchase-type-error-msg").display =
      "block"
    return
  }
  ioCheckoutElement.querySelector("#purchase-type-error-msg").innerText = ""
  ioCheckoutElement.querySelector("#purchase-type-error-msg").display = "none"
}

const handleTelephoneInput = (fields, isNew, isEdit) => {
  handlePhoneField(fields, isNew, isEdit)
  hideTelephoneField()
}

const handlePhoneField = (fields, isNew, isEdit) => {
  const addressType = getAddressType(fields)
  const inputs = ioCheckoutElement.querySelectorAll(".intlTelInput-phone")
  if (inputs.length > 0) {
    inputs.forEach(async (input, key) => {
      // Closest Error Element
      const closestTelephoneField = input
        .closest("form")
        .querySelector('input[name="telephone"]')

      // Compoenent Type
      let type = BILLING_ADDRESS_CODE

      if (closestTelephoneField) {
        if (
          closestTelephoneField.id &&
          closestTelephoneField.id.indexOf("shipping") > -1
        ) {
          type = SHIPPING_ADDRESS_CODE
        }
      }

      if (
        type !== addressType ||
        (intlTelInputObject[addressType] && !isNew && !isEdit)
      ) {
        return
      }

      intlTelInputObject[addressType] = intlTelInput(input, {
        initialCountry: "zm",
        separateDialCode: true,
        loadUtils: () =>
          import(
            "https://cdn.jsdelivr.net/npm/intl-tel-input@25.3.0/build/js/utils.js"
          ), // for formatting/placeholders etc
      })

      // Set Default Telephone Number
      const errorMsgElement = input
        .closest("form")
        .querySelector(".intlTelInput-phone-error-msg")
      errorMsgElement.style.display = "none"
      const cartPhoneNumber = await getCartPhoneNumber(addressType)
      if (cartPhoneNumber && !isNewAddressFields) {
        await intlTelInputObject[addressType].setNumber(`${cartPhoneNumber}`)
      }

      // Phone Input update
      input.addEventListener("keyup", (e) => {
        let phoneNumber = intlTelInputObject[addressType].getNumber()

        const errorMsgElement = input
          .closest("form")
          .querySelector(".intlTelInput-phone-error-msg")
        errorMsgElement.style.display = "none"

        if (errorMsgElement) {
          if (!intlTelInputObject[addressType].isValidNumber()) {
            errorMsgElement.style.display = "block"
            phoneNumber = ""
            closestTelephoneField.value = ""
          }
        }

        updateTelephoneField(type, phoneNumber)
      })
    })
  }
}

const updateTelephoneField = async (type, value) => {
  let fields = getAddressFields(type)

  fields.forEach((field, key) => {
    if (field.id === "telephone") {
      field.data_type = "text"
      //  field.value = value.replace("+", "")
      field.value = value
    }
    fields[key] = field
  })

  // Update Fields
  await renderComponentFields(fields, type, true)
  await assignAddressFields(fields, type)

  // Save Address
  if (value) {
    clearTimeout(debounsSaveAddress)
    debounsSaveAddress = setTimeout(() => {
      saveAddressesFunc(type)
    }, 1000)
  }
}

const hideTelephoneField = () => {
  const telephoneElements = ioCheckoutElement.querySelectorAll(
    'input[name="telephone"]'
  )
  if (telephoneElements) {
    telephoneElements.forEach((telephoneElement) => {
      telephoneElement.style.display = "none"
    })
  }
}

// Get Regions
const getRegionColl = () => {
  const state = getStore().getState()
  collectionOfRegions = state.CityPricingReducer.regions
}

// Get Region City Collection
const getRegionCityColl = async () => {
  const state = getStore().getState()
  collectionOfCities = state.CityPricingReducer.cities
}

const renderCityAndRegion = async (fields, isNew, isEdit) => {
  const addressType = await getAddressType(fields)

  // Region
  let selectedRegion = await getHeaderSelectedRegionValue()
  if (isNew) {
    selectedRegion =
      collectionOfRegions.length > 0 ? collectionOfRegions[0].region_id : ""
  } else if (isEdit) {
    fields.forEach((field) => {
      if (field.id === "region_id") {
        selectedRegion = field.value
      }
    })
  }

  // City
  let selectedCity = await getHeaderSelectedCityValue()
  let cityOptions = await getCityOptions(selectedRegion)
  if (isNew) {
    selectedCity = cityOptions.length > 0 ? cityOptions[0].value : ""
  } else if (isEdit) {
    fields.forEach((field) => {
      if (field.id === "city") {
        selectedCity = field.value
      }
    })
  }

  fields.forEach((field, key) => {
    if (field.id === "telephone") {
      field.data_type = "text"
    }
    if (field.id === "city") {
      field.component = "SelectOption"
      field.data_type = "select"
      field.front_type = "dropdown"
      field.isLabelShow = true
      field.isSearch = false
      field.select_options = cityOptions
      field.value = selectedCity ? selectedCity : field.value
      field.input_group_class = "label-top readonly"
    }

    if (field.id === "region_id") {
      field.value = selectedRegion
      field.input_group_class = "label-top readonly"
    }

    if (field.id === "country_id") {
      field.input_group_class = "label-top readonly"
    }

    if (field.id === "sameAsShippingAddress") {
      field.value = false
    }

    if (isNew && !["city", "region_id", "country_id"].includes(field.id)) {
      field.value = ""
    }

    if (field.id === "postcode") {
      field.type = "hidden"
      field.value = "10101"
    }

    fields[key] = field
  })

  //   Update Fields
  await renderComponentFields(fields, addressType, true)

  //  if (
  //    !isNewAddressFields &&
  //    cart &&
  //    cart.shipping_addresses &&
  //    cart.shipping_addresses.length > 0
  //  ) {
  //    await setTimeout(() => {
  //      saveAddressesFunc(addressType)
  //    }, 1500)
  //  }
}

const getCityOptions = (regionId) => {
  let cityOptions = []

  collectionOfCities.forEach((city) => {
    if (city.region_id === regionId) {
      cityOptions.push({
        text: city.city_name,
        value: city.city_name,
      })
    }
  })

  return cityOptions
}

const getHeaderSelectedCityValue = async () => {
  let selectedCity = ""

  const state = getStore().getState()
  const cityPricingReducer = state.CityPricingReducer
  selectedCity = cityPricingReducer.savedCookie.city

  return selectedCity
}

const getHeaderSelectedRegionValue = async () => {
  let selectedRegion = ""

  // Initial Load
  const state = getStore().getState()
  const cityPricingReducer = state.CityPricingReducer
  selectedRegion = cityPricingReducer.savedCookie.region_id

  return selectedRegion
}

const redirectToCartPage = () => {
  window.location.href = window.location.origin + "/cart"
}
const getCart = () => {
  if (ioCheckoutStore?._state?.data?.magentogq?.cartData?.cart) {
    return JSON.parse(
      JSON.stringify(ioCheckoutStore._state.data.magentogq.cartData.cart)
    )
  }
  return {}
}

const getCartPhoneNumber = (type) => {
  let number = ""
  const cart = getCart()
  if (type === SHIPPING_ADDRESS_CODE && cart?.shipping_addresses.length > 0) {
    number = cart.shipping_addresses[0].telephone
  } else if (cart?.billing_address?.telephone) {
    number = cart.billing_address.telephone
  }
  return number
}

const getAddressType = (fields) => {
  return fields[0].addressComponent
}

const assignAddressFields = (fields, type) => {
  if (type === SHIPPING_ADDRESS_CODE) {
    shippingAddressFields = fields
  } else {
    billingAddressFields = fields
  }
}

const getAddressFields = (type) => {
  if (type === SHIPPING_ADDRESS_CODE) {
    return shippingAddressFields
  } else {
    return billingAddressFields
  }
}

const hideShippingAddress = () => {
  const shippingAddressHiddenInterval = setInterval(() => {
    const shippingAddress = ioCheckoutElement.querySelector("#shippingAddress")
    if (shippingAddress) {
      clearInterval(shippingAddressHiddenInterval)
      shippingAddress.style.display = "none"
    }
  }, 10)
}

const saveShippingAddress = async (params) => {
  try {
    if (params.address) {
      ioCheckoutStore.dispatch("magentogq/setShippingAddressOnCartAction", [
        params,
      ])
      return
    }
    ioCheckoutStore.dispatch("magentogq/setShippingAddressOnCartAction", [
      params,
    ])
  } catch (error) {
    console.log("ioCheckout > saveShippingAddress > error", error)
  }
}

const getStorePickupList = async () => {
  const selectedCity = await getHeaderSelectedCityValue()
  selectedStoreLocatorId = 0
  try {
    const filter = {
      city: {
        eq: selectedCity,
      },
    }
    const {
      MpStoreLocatorLocations: { items },
    } = await fetchQuery(StoreLocatorQuery.getStoreLocatorsList(filter, 50, 1))
    storeLocatorList = items
    await checkAndUpdateSelectedLocationId()
  } catch (error) {
    storeLocatorList = []
  }
}

// update selected store locator id depending on cart shipping address
const checkAndUpdateSelectedLocationId = async () => {
  try {
    const cart = await getCart()
    const selectedCity = await getHeaderSelectedCityValue()

    // Checking cart shipping address and store locator list
    if (
      cart &&
      cart.shipping_addresses &&
      cart.shipping_addresses.length > 0 &&
      storeLocatorList.length > 0
    ) {
      storeLocatorList.forEach((store) => {
        if (store.name == cart.shipping_addresses[0].firstname) {
          selectedStoreLocatorId = store.location_id
        }
      })
    } else if (collectionOfCities.length > 0) {
      // default store
      collectionOfCities.forEach((city) => {
        if (city.city_name == selectedCity && city.default_store != null) {
          selectedStoreLocatorId = city.default_store.location_id
        }
      })
    }

    if (selectedStoreLocatorId === 0 && storeLocatorList.length > 0) {
      selectedStoreLocatorId = storeLocatorList[0].location_id
    }
  } catch (error) {
    console.log("ioCheckout > checkAndUpdateSelectedLocationId > error", error)
  }
}

const handleCustomEventShippingMethod = () => {
  const storeLocatorElementInterval = setInterval(async () => {
    const storeLocatorElement =
      ioCheckoutElement.querySelector("#storesLocator")
    if (storeLocatorElement) {
      await clearInterval(storeLocatorElementInterval)
      await checkAndUpdateSelectedLocationId()
      await renderSelectedStoreLocator()
      await saveStoreLocator()
      storeLocatorElement.addEventListener("change", async (event) => {
        selectedStoreLocatorId = event.target.value
        await addLoadingGlobal()
        await renderSelectedStoreLocator()
        await saveStoreLocator()
      })
    }
  }, 10)
}

const renderSelectedStoreLocator = () => {
  const selectedStoreLocator = storeLocatorList.find(
    (store) => store.location_id == selectedStoreLocatorId
  )

  if (selectedStoreLocator) {
    const renderSelectedStoreLocatorInterval = setInterval(() => {
      const storesLocatorSelectedNameElement = ioCheckoutElement.querySelector(
        ".storesLocator-selected-name"
      )
      const storesLocatorSelectedAddressElement =
        ioCheckoutElement.querySelector(".storesLocator-selected-address")

      if (
        storesLocatorSelectedNameElement &&
        storesLocatorSelectedAddressElement
      ) {
        clearInterval(renderSelectedStoreLocatorInterval)

        storesLocatorSelectedNameElement.innerHTML = `<strong>Store Name</strong><span>${selectedStoreLocator.name}<span>`

        storesLocatorSelectedAddressElement.innerHTML = `<strong>Store Address</strong>
		<address>
			<span class="mpstore-street">${selectedStoreLocator.street},</span>
			<span class="mpstore-city-postcode">${selectedStoreLocator.city}, ${selectedStoreLocator.postal_code},</span>
			<span class="mpstore-state">${selectedStoreLocator.state_province}, Zambia,</span>
			<span class="mpstore-phone">${selectedStoreLocator.phone_one}</span>
		</address>`
      }
    }, 10)
  }

  selectStoreLocator()
}

const selectStoreLocator = async () => {
  const storeLocatorElementInterval = setInterval(() => {
    const storeLocatorElement =
      ioCheckoutElement.querySelector("#storesLocator")
    if (storeLocatorElement) {
      clearInterval(storeLocatorElementInterval)
      storeLocatorElement.value = selectedStoreLocatorId
    }
  }, 10)
}

const saveStoreLocator = async () => {
  await addLoadingGlobal()
  ioCheckoutStore.commit("magentogq/setServerShippingMethodError", null)
  try {
    const input = {
      locationId: selectedStoreLocatorId,
    }
    await fetchMutation(
      StoreLocatorQuery.SaveLocationElightwalkStoreLocator(input)
    )
    await removeLoadingGlobal()
  } catch (error) {
    ioCheckoutStore.commit(
      "magentogq/setServerShippingMethodError",
      error.message
    )
    await removeLoadingGlobal()
  }
}

const getPaymentMethodInstruction = () => {
  fetchQuery(IoCheckoutQuery.getPaymentMethodsInstructions()).then(
    (response) => {
      const { getPaymentMethodInstrucationsElightwalk } = response
      paymentMethodInstrction = getPaymentMethodInstrucationsElightwalk
    }
  )
}

const renderInstrcution = (instrcution) => {
  return instrcution.replace(/(\r\n|\r|\n)/g, "<br>")
}

const isLoginCustomer = () => {
  if (
    ioCheckoutStore &&
    ioCheckoutStore._modules &&
    ioCheckoutStore._modules.root &&
    ioCheckoutStore._modules.root.state &&
    ioCheckoutStore._modules.root.state.magentogq &&
    ioCheckoutStore._modules.root.state.magentogq.clientConfig &&
    ioCheckoutStore._modules.root.state.magentogq.clientConfig.isLogin &&
    isSignedIn()
  ) {
    return true
  }
  return false
}

const handleSelectedCityLoginCustomerCity = async () => {
  const selectedCity = await getHeaderSelectedCityValue()
  const selectedRegion = await getHeaderSelectedRegionValue()
  const isLogin = await isLoginCustomer()
  const cart = await getCart()
  let isRedirectToCartPage = false
  let newSelectedCity = selectedCity
  let newSelectedRegion = selectedRegion
  let customerAddressId = 0

  await addLoadingGlobal()
  if (isLogin && selectedCity) {
    // Cart have not shipping address
    if (Object.keys(cart).length > 0 && cart.shipping_addresses.length === 0) {
      await fetchQuery(MyAccountQuery.getCustomerQuery()).then((response) => {
        const { customer } = response
        if (customer && customer.addresses && customer.addresses.length > 0) {
          customer.addresses.forEach((address) => {
            if (address.default_billing === true) {
              isRedirectToCartPage = true
              newSelectedCity = address.city
              newSelectedRegion = address.region.region_id
              customerAddressId = address.id
            }
          })
        }
      })
    }

    // Checking cart have shipping address but address city not match with selected city
    if (Object.keys(cart).length > 0 && cart.shipping_addresses.length > 0) {
      if (cart.shipping_addresses[0].city !== selectedCity) {
        isRedirectToCartPage = true
        newSelectedCity = cart.shipping_addresses[0].city
        newSelectedRegion = cart.shipping_addresses[0].region.region_id
      }
    }
  }

  // Assign Address city and reload the page
  if (isRedirectToCartPage) {
    if (customerAddressId > 0) {
      const params = {
        customer_address_id: customerAddressId,
      }
      await saveShippingAddress(params)
    }
    await fetchMutation(
      CityPricingQuery.saveCityCookie({
        region_id: newSelectedRegion,
        city: newSelectedCity,
      })
    )

    //// Clear cache
    await sessionStorage.clear()
    await removeAllCacheStorage()
    await localStorage.setItem("ioCheckoutRedirectToCart", newSelectedCity)
    await redirectToCartPage()
  }
  await removeLoadingGlobal()
}

const getOrder = () => {
  if (
    ioCheckoutStore &&
    ioCheckoutStore._state &&
    ioCheckoutStore._state.data &&
    ioCheckoutStore._state.data.magentogq &&
    ioCheckoutStore._state.data.magentogq.orderData
  ) {
    return JSON.parse(
      JSON.stringify(ioCheckoutStore._state.data.magentogq.orderData)
    )
  }
  return {}
}

// Payment Method
const handleDpoPaymentMethod = async () => {
  try {
    ioCheckoutStore.commit(
      "magentogq/setLoadingArray",
      CUSTOM_PAYMENT_METHOD_CODE_DPO
    )
    const cart = await getCart()

    const params = {
      input: {
        cart_id: cart.id,
      },
      isRedirectToSuccess: false,
    }
    await ioCheckoutStore.dispatch("magentogq/placeOrderOnCartAction", params)

    let order = await getOrder()
    await localStorage.setItem(
      ORDER_DETAILS,
      JSON.stringify({
        orderDetailsData: {
          cart: cart,
        },
        order,
      })
    )
    await fetchMutation(IoCheckoutQuery.redirectToDpo(order.order_number)).then(
      (response) => {
        const {
          redirectToDpo: { status, message, payUrl, ID },
        } = response

        if (status) {
          ioCheckoutElement.getElementById("dpo-id").value = ID
          ioCheckoutElement.getElementById("dpo_checkout").action = payUrl
          ioCheckoutElement.getElementById("dpo_checkout").submit()
        } else {
          localStorage.removeItem(ORDER_DETAILS)
          ioCheckoutStore.dispatch("magentogq/restoreCartAction")
          ioCheckoutStore.commit(
            "magentogq/setPaymentMethodSelectError",
            message
          )
          ioCheckoutStore.commit("magentogq/removeAllLoadingArray")
        }
      }
    )
  } catch (error) {
    localStorage.removeItem(ORDER_DETAILS)
    ioCheckoutStore.commit(
      "magentogq/setPaymentMethodSelectError",
      error.message
    )
    ioCheckoutStore.commit("magentogq/removeAllLoadingArray")
  }
}

document.addEventListener(CUSTOM_PAYMENT_METHOD_CODE_DPO, async (event) => {
  handleDpoPaymentMethod()
})

document.addEventListener(
  CUSTOM_PAYMENT_METHOD_CODE_STOREPAYMENT,
  async (event) => {
    handleStorePaymentPaymentMethod()
  }
)

const hideInstructionElement = (className) => {
  const intervalOfInstruction = setInterval(() => {
    if (ioCheckoutElement) {
      clearInterval(intervalOfInstruction)
      const instructionElement = ioCheckoutElement.querySelector(
        `.${className}`
      )
      if (instructionElement) {
        instructionElement.style.display = "none"
      }
    }
  }, 1)
}

const showInstructionElement = (className) => {
  const intervalOfInstruction = setInterval(() => {
    if (ioCheckoutElement) {
      clearInterval(intervalOfInstruction)
      const instructionElement = ioCheckoutElement.querySelector(
        `.${className}`
      )
      if (instructionElement) {
        instructionElement.style.display = "block"
      }
    }
  }, 1)
}

const handlePurchaseTypesError = (element = null) => {
  if (element !== null) {
    element.parentNode.classList.remove("error")
    if (element.value === "") {
      element.parentNode.classList.add("error")
    }
  } else {
    const businessTpinNumberElement = ioCheckoutElement.querySelector(
      'input[name="business_tpin_number"]'
    )
    if (businessTpinNumberElement) {
      businessTpinNumberElement.parentNode.classList.remove("error")
    }

    const businessNameElement = ioCheckoutElement.querySelector(
      'input[name="business_name"]'
    )
    if (businessNameElement) {
      businessNameElement.parentNode.classList.remove("error")
    }
  }
}

const getStorePaymentRefNumber = async () => {
  try {
    const cart = await getCart()
    if (Object.keys(cart).length > 0 && storePaymentRefId === "") {
      addLoadingGlobal()
      const cartId = cart.id
      fetchQuery(IoCheckoutQuery.getStorePaymentRefNumber(cartId)).then(
        (response) => {
          const { getStorePaymentRefNumber } = response
          storePaymentRefId = getStorePaymentRefNumber
          removeLoadingGlobal()
        }
      )
    }
  } catch (e) {
    removeLoadingGlobal()
  }
}

const handleCustomEventPaymentMethod = async () => {
  try {
    const posReferenceNumberElementInterval = setInterval(async () => {
      const cart = await getCart()

      const posReferenceNumberElement = ioCheckoutElement.querySelector(
        "#pos_reference_number"
      )
      if (Object.keys(cart).length > 0 && posReferenceNumberElement) {
        clearInterval(posReferenceNumberElementInterval)
        posReferenceNumberElement.addEventListener("input", (e) => {
          placeOrderBtnEnableDisable()
        })
      }

      placeOrderBtnEnableDisable()
    }, 10)
  } catch (e) {
    console.log("handleCustomEventPaymentMethod error ", e)
  }
}

const beforeAllowPaymentMethod = async () => {
  let isAllowPaymentMethod = true

  if (selectedPaymentMethod === CUSTOM_PAYMENT_METHOD_CODE_STOREPAYMENT) {
    const posReferenceNumberElement = await ioCheckoutElement.querySelector(
      "#pos_reference_number"
    )

    if (!posReferenceNumberElement?.value) {
      isAllowPaymentMethod = false
    }
  }

  if (
    selectedPaymentMethod === CUSTOM_PAYMENT_METHOD_CODE_ZAMPAY &&
    phoneNumberForZampayPayment === null
  ) {
    isAllowPaymentMethod = false
  }

  if (
    selectedPaymentMethod === CUSTOM_PAYMENT_METHOD_CODE_AIRTEL &&
    phoneNumberForAirtelPayment === null
  ) {
    isAllowPaymentMethod = false
  }

  if (
    selectedPaymentMethod === CUSTOM_PAYMENT_METHOD_CODE_MTN &&
    phoneNumberForMtnPayment === null
  ) {
    isAllowPaymentMethod = false
  }

  return isAllowPaymentMethod
}

const placeOrderBtnEnableDisable = async () => {
  const placeOrderBtn = ioCheckoutElement.querySelector("#placeOrderButton")

  const selectedPurchaseType = await ioCheckoutElement.querySelector(
    'input[name="purchaseType"]:checked'
  )
  const businessTpinNumber = await ioCheckoutElement.querySelector(
    'input[name="business_tpin_number"]'
  )
  const businessName = await ioCheckoutElement.querySelector(
    'input[name="business_name"]'
  )

  let isPlaceOrder = false
  let isAllowPaymentMethod = await beforeAllowPaymentMethod()

  if (
    placeOrderBtn &&
    selectedPurchaseType?.value !== "business" &&
    isAllowPaymentMethod
  ) {
    placeOrderBtn.removeAttribute("disabled")
    isPlaceOrder = true
  } else if (
    placeOrderBtn &&
    selectedPurchaseType?.value === "business" &&
    businessTpinNumber?.value !== "" &&
    businessName?.value !== "" &&
    isAllowPaymentMethod
  ) {
    placeOrderBtn.removeAttribute("disabled")
    isPlaceOrder = true
  } else if (placeOrderBtn) {
    placeOrderBtn.setAttribute("disabled", true)
    isPlaceOrder = false
  }

  if (setIsPlaceOrderEnableGlobal) {
    setIsPlaceOrderEnableGlobal(isPlaceOrder)
  }
}

const handleStorePaymentPaymentMethod = async () => {
  try {
    addLoadingGlobal()
    const posReferenceNumberElement = ioCheckoutElement.querySelector(
      "#pos_reference_number"
    )

    const cartId = await getCart().id

    const params = {
      cart_id: cartId,
      payment_method: {
        code: CUSTOM_PAYMENT_METHOD_CODE_STOREPAYMENT,
        storepayment: {
          pos_reference_number: posReferenceNumberElement.value,
          receipt_reference_id: storePaymentRefId,
        },
      },
    }

    await fetchMutation(IoCheckoutQuery.setPaymentMethodOnCart(params)).then(
      (res) => {
        const params = {
          input: {
            cart_id: cartId,
          },
          isRedirectToSuccess: true,
        }
        ioCheckoutStore.dispatch("magentogq/placeOrderOnCartAction", params)
      }
    )
  } catch (error) {
    ioCheckoutStore.commit("magentogq/setpaymentFailedError", error[0].message)
    removeLoadingGlobal()
  }
}

const showPaymentErrorElement = (paymentCode) => {
  const paymentFieldElement = ioCheckoutElement.querySelector(
    `.${paymentCode}-payment-field`
  )
  if (paymentFieldElement) {
    const errorMsgElement = paymentFieldElement.querySelector(
      ".intlTelInput-phone-error-msg"
    )
    errorMsgElement.style.display = "block"
  }
}

const hidePaymentErrorElement = (paymentCode) => {
  const paymentFieldElement = ioCheckoutElement.querySelector(
    `.${paymentCode}-payment-field`
  )
  if (paymentFieldElement) {
    const errorMsgElement = paymentFieldElement.querySelector(
      ".intlTelInput-phone-error-msg"
    )
    errorMsgElement.style.display = "none"
  }
}

// Zampay Payment Method
document.addEventListener(CUSTOM_PAYMENT_METHOD_CODE_ZAMPAY, async (event) => {
  handleZampayPaymentMethod()
})

const loadZampayTelephoneElement = () => {
  const elementInterval = setInterval(() => {
    const zampayPhoneElement = ioCheckoutElement.querySelector(
      ".intlTelInput-phone-zampay"
    )
    if (zampayPhoneElement) {
      clearInterval(elementInterval)

      hidePaymentErrorElement(CUSTOM_PAYMENT_METHOD_CODE_ZAMPAY)

      if (zampayPhoneNumberElement !== null) {
        return
      }

      zampayPhoneNumberElement = zampayPhoneElement

      const intlTelInputObject = intlTelInput(zampayPhoneElement, {
        initialCountry: "zm",
        separateDialCode: true,
        loadUtils: () =>
          import(
            "https://cdn.jsdelivr.net/npm/intl-tel-input@25.3.0/build/js/utils.js"
          ), // for formatting/placeholders etc
      })

      dialCodeForZampayPayment =
        intlTelInputObject.getSelectedCountryData().dialCode

      zampayPhoneElement.addEventListener("keyup", async (e) => {
        phoneNumberForZampayPayment = await intlTelInputObject.getNumber()

        hidePaymentErrorElement(CUSTOM_PAYMENT_METHOD_CODE_ZAMPAY)

        if (!intlTelInputObject.isValidNumber()) {
          showPaymentErrorElement(CUSTOM_PAYMENT_METHOD_CODE_ZAMPAY)
          phoneNumberForZampayPayment = null
        }
        await placeOrderBtnEnableDisable()
      })

      zampayPhoneElement.addEventListener("countrychange", async (e) => {
        dialCodeForZampayPayment =
          await intlTelInputObject.getSelectedCountryData().dialCode
        await intlTelInputObject.setNumber(`+${dialCodeForZampayPayment}`)
        await placeOrderBtnEnableDisable()
      })
    }
  }, 10)
}

const handleZampayPaymentMethod = async () => {
  try {
    // Error
    if (!phoneNumberForZampayPayment) {
      showPaymentErrorElement(CUSTOM_PAYMENT_METHOD_CODE_ZAMPAY)
      return
    }

    addLoadingGlobal()

    const cartId = await getCart().id

    const params = {
      cart_id: cartId,
      payment_method: {
        code: CUSTOM_PAYMENT_METHOD_CODE_ZAMPAY,
        zampay: {
          zampay_country: `+${dialCodeForZampayPayment}`,
          zampay_number: phoneNumberForZampayPayment.replace(
            `+${dialCodeForZampayPayment}`,
            ""
          ),
        },
      },
    }

    await fetchMutation(IoCheckoutQuery.setPaymentMethodOnCart(params)).then(
      (res) => {
        const params = {
          input: {
            cart_id: cartId,
          },
          isRedirectToSuccess: true,
        }
        ioCheckoutStore.dispatch("magentogq/placeOrderOnCartAction", params)
      }
    )
  } catch (error) {
    ioCheckoutStore.commit("magentogq/setpaymentFailedError", error[0].message)
    removeLoadingGlobal()
  }
}

// Airtel Payement Method
document.addEventListener(CUSTOM_PAYMENT_METHOD_CODE_AIRTEL, async (event) => {
  handleAirtelPaymentMethod()
})

const loadAirtelTelephoneElement = () => {
  const elementInterval = setInterval(() => {
    const phoneElement = ioCheckoutElement.querySelector(
      ".intlTelInput-phone-airtel"
    )
    if (phoneElement) {
      clearInterval(elementInterval)

      hidePaymentErrorElement(CUSTOM_PAYMENT_METHOD_CODE_AIRTEL)

      if (airtelPhoneNumberElement !== null) {
        return
      }

      airtelPhoneNumberElement = phoneElement

      const intlTelInputObject = intlTelInput(phoneElement, {
        initialCountry: "zm",
        separateDialCode: true,
        customPlaceholder: function (
          selectedCountryPlaceholder,
          selectedCountryData
        ) {
          return "97 7123456"
        },
        loadUtils: () =>
          import(
            "https://cdn.jsdelivr.net/npm/intl-tel-input@25.3.0/build/js/utils.js"
          ), // for formatting/placeholders etc
      })

      dialCodeForAirtelPayment =
        intlTelInputObject.getSelectedCountryData().dialCode

      phoneElement.addEventListener("keyup", async (e) => {
        phoneNumberForAirtelPayment = await intlTelInputObject.getNumber()

        hidePaymentErrorElement(CUSTOM_PAYMENT_METHOD_CODE_AIRTEL)

        if (!intlTelInputObject.isValidNumber()) {
          showPaymentErrorElement(CUSTOM_PAYMENT_METHOD_CODE_AIRTEL)
          phoneNumberForAirtelPayment = null
        }
        await placeOrderBtnEnableDisable()
      })

      phoneElement.addEventListener("countrychange", async (e) => {
        dialCodeForAirtelPayment =
          await intlTelInputObject.getSelectedCountryData().dialCode
        await intlTelInputObject.setNumber(`+${dialCodeForAirtelPayment}`)
        await placeOrderBtnEnableDisable()
      })
    }
  }, 10)
}

const handleAirtelPaymentMethod = async () => {
  try {
    // Error
    if (!phoneNumberForAirtelPayment) {
      showPaymentErrorElement(CUSTOM_PAYMENT_METHOD_CODE_AIRTEL)
      return
    }

    addLoadingGlobal()

    const cartId = await getCart().id

    const params = {
      cart_id: cartId,
      payment_method: {
        code: CUSTOM_PAYMENT_METHOD_CODE_AIRTEL,
        airtel: {
          airtel_country: `+${dialCodeForAirtelPayment}`,
          airtel_number: phoneNumberForAirtelPayment.replace(
            `+${dialCodeForAirtelPayment}`,
            ""
          ),
        },
      },
    }

    await fetchMutation(IoCheckoutQuery.setPaymentMethodOnCart(params)).then(
      (res) => {
        const params = {
          input: {
            cart_id: cartId,
          },
          isRedirectToSuccess: true,
        }
        ioCheckoutStore.dispatch("magentogq/placeOrderOnCartAction", params)
      }
    )
  } catch (error) {
    ioCheckoutStore.commit("magentogq/setpaymentFailedError", error[0].message)
    removeLoadingGlobal()
  }
}

// Mtn Payement Method
document.addEventListener(CUSTOM_PAYMENT_METHOD_CODE_MTN, async (event) => {
  handleMtnPaymentMethod()
})

const loadMtnTelephoneElement = () => {
  const elementInterval = setInterval(() => {
    const phoneElement = ioCheckoutElement.querySelector(
      ".intlTelInput-phone-mtn"
    )
    if (phoneElement) {
      clearInterval(elementInterval)

      hidePaymentErrorElement(CUSTOM_PAYMENT_METHOD_CODE_MTN)

      if (mtnPhoneNumberElement !== null) {
        return
      }

      mtnPhoneNumberElement = phoneElement

      const intlTelInputObject = intlTelInput(phoneElement, {
        initialCountry: "zm",
        separateDialCode: true,
        customPlaceholder: function (
          selectedCountryPlaceholder,
          selectedCountryData
        ) {
          return "96 6123456"
        },
        loadUtils: () =>
          import(
            "https://cdn.jsdelivr.net/npm/intl-tel-input@25.3.0/build/js/utils.js"
          ), // for formatting/placeholders etc
      })

      dialCodeForMtnPayment =
        intlTelInputObject.getSelectedCountryData().dialCode

      phoneElement.addEventListener("keyup", async (e) => {
        phoneNumberForMtnPayment = await intlTelInputObject.getNumber()

        hidePaymentErrorElement(CUSTOM_PAYMENT_METHOD_CODE_MTN)

        if (!intlTelInputObject.isValidNumber()) {
          showPaymentErrorElement(CUSTOM_PAYMENT_METHOD_CODE_MTN)
          phoneNumberForMtnPayment = null
        }
        await placeOrderBtnEnableDisable()
      })

      phoneElement.addEventListener("countrychange", async (e) => {
        dialCodeForMtnPayment =
          await intlTelInputObject.getSelectedCountryData().dialCode
        await intlTelInputObject.setNumber(`+${dialCodeForMtnPayment}`)
        await placeOrderBtnEnableDisable()
      })
    }
  }, 10)
}

const handleMtnPaymentMethod = async () => {
  try {
    // Error
    if (!phoneNumberForMtnPayment) {
      showPaymentErrorElement(CUSTOM_PAYMENT_METHOD_CODE_MTN)
      return
    }

    addLoadingGlobal()

    const cartId = await getCart().id

    const params = {
      cart_id: cartId,
      payment_method: {
        code: CUSTOM_PAYMENT_METHOD_CODE_MTN,
        mtn: {
          mtn_country: `+${dialCodeForMtnPayment}`,
          mtn_number: phoneNumberForMtnPayment.replace(
            `+${dialCodeForMtnPayment}`,
            ""
          ),
        },
      },
    }

    await fetchMutation(IoCheckoutQuery.setPaymentMethodOnCart(params)).then(
      (res) => {
        const params = {
          input: {
            cart_id: cartId,
          },
          isRedirectToSuccess: true,
        }
        ioCheckoutStore.dispatch("magentogq/placeOrderOnCartAction", params)
      }
    )
  } catch (error) {
    ioCheckoutStore.commit("magentogq/setpaymentFailedError", error[0].message)
    removeLoadingGlobal()
  }
}

// const signupElement = () => {
//   const signupInterval = setInterval(() => {
//     const forgotPasswordBox = ioCheckoutElement.querySelector(".forgot-password-box")
//     if (forgotPasswordBox) {
//       clearInterval(signupInterval)

//       // Set the signup link
//       signupContent = `<a href="${window.location.origin}/customer/account/create">Sign Up</a>`;
//       forgotPasswordBox = document.querySelector(".forgot-password-box");
//       forgotPasswordBox.innerHTML += signupContent;
//       console.log("signupContent", signupContent);
//       console.log("forgotPasswordBox", forgotPasswordBox);

//     }
//   }, 10)
// }
