Mosaic.addPlugins([require('/var/www/html/src/localmodules/my-app/node_modules/@elightwalk/iocheckout/src/plugin/HeaderFallback.plugin.tsx'),require('/var/www/html/src/localmodules/my-app/node_modules/@elightwalk/iocheckout/src/plugin/Route.plugin.tsx'),require('/var/www/html/src/localmodules/my-app/node_modules/@elightwalk/faqs/src/plugin/Router.component.plugin.js')]);
/* eslint-disable react/jsx-no-bind */
/* eslint-disable */
/* stylelint-disable */
/* eslint-disable spaced-comment */

import { lazy } from "react"
import { Route } from "react-router-dom"

import { PrintTypes } from "Component/MyAccountOrderPrint/MyAccountOrderPrint.config"
import UrlRewrites from "Route/UrlRewrites"
import { MyAccountTabs } from "Type/Account.type"
import { lowPriorityLazy } from "Util/Request/LowPriorityRender"
import { appendWithStoreCode, isHomePageUrl } from "Util/Url"
import { RouterItemType, RouterSwitchItemType } from "./Router.config"

import "./Router.style"

export const CartPage = lazy(() =>
  import(/* webpackMode: "lazy", webpackChunkName: "cart" */ "Route/CartPage")
)
export const Checkout = lazy(() =>
  import(
    /* webpackMode: "lazy", webpackChunkName: "checkout" */ "Route/Checkout"
  )
)
export const CmsPage = lazy(() =>
  import(/* webpackMode: "lazy", webpackChunkName: "cms" */ "Route/CmsPage")
)
export const HomePage = lazy(() =>
  import(/* webpackMode: "lazy", webpackChunkName: "cms" */ "Route/HomePage")
)
export const MyAccount = lazy(() =>
  import(
    /* webpackMode: "lazy", webpackChunkName: "account" */ "Route/MyAccount"
  )
)
export const PasswordChangePage = lazy(() =>
  import(
    /* webpackMode: "lazy", webpackChunkName: "misc" */ "Route/PasswordChangePage"
  )
)
export const SearchPage = lazy(() =>
  import(
    /* webpackMode: "lazy", webpackChunkName: "search" */ "Route/SearchPage"
  )
)
export const SendConfirmationPage = lazy(() =>
  import(
    /* webpackMode: "lazy", webpackChunkName: "misc" */ "Route/SendConfirmationPage"
  )
)
export const ConfirmAccountPage = lazy(() =>
  import(
    /* webpackMode: "lazy", webpackChunkName: "account" */ "Route/ConfirmAccountPage"
  )
)
export const MenuPage = lazy(() =>
  import(/* webpackMode: "lazy", webpackChunkName: "menu" */ "Route/MenuPage")
)
export const WishlistShared = lazy(() =>
  import(
    /* webpackMode: "lazy", webpackChunkName: "misc" */ "Route/WishlistSharedPage"
  )
)
export const ContactPage = lazy(() =>
  import(
    /* webpackMode: "lazy", webpackChunkName: "contact" */ "Route/ContactPage"
  )
)
export const ProductComparePage = lazy(() =>
  import(
    /* webpackMode: "lazy", webpackChunkName: "compare" */ "Route/ProductComparePage"
  )
)
export const CreateAccountPage = lazy(() =>
  import(
    /* webpackMode: "lazy", webpackChunkName: "account" */ "Route/CreateAccount"
  )
)
export const LoginAccountPage = lazy(() =>
  import(
    /* webpackMode: "lazy", webpackChunkName: "account" */ "Route/LoginAccount"
  )
)
export const ForgotPasswordPage = lazy(() =>
  import(
    /* webpackMode: "lazy", webpackChunkName: "account" */ "Route/ForgotPassword"
  )
)
export const StyleGuidePage = lazy(() =>
  import(
    /* webpackMode: "lazy", webpackChunkName: "styleguide" */ "Route/StyleGuidePage"
  )
)
export const OrderPrintPage = lazy(() =>
  import(
    /* webpackMode: "lazy", webpackChunkName: "print-order" */ "Route/OrderPrintPage"
  )
)
export const MobileAccountPage = lowPriorityLazy(() =>
  import(
    /* webpackMode: "lazy", webpackChunkName: "mobileaccount" */ "Route/MobileAccountPage"
  )
)
export const Ordertracking = lowPriorityLazy(() =>
  import(
    /* webpackMode: "lazy", webpackChunkName: "ordertracking" */ "Route/OrdertrackingPage"
  )
)
export const StoreLocator = lowPriorityLazy(() =>
  import(
    /* webpackMode: "lazy", webpackChunkName: "StoreLocator" */ "Route/StoreLocatorPage"
  )
)
export const DpoSuccessPage = lowPriorityLazy(() =>
  import(
    /* webpackMode: "lazy", webpackChunkName: "DpoSuccess" */ "Route/DpoSuccessPage"
  )
)
export const DpoLayByProcessPage = lowPriorityLazy(() =>
  import(
    /* webpackMode: "lazy", webpackChunkName: "DpoLayByProcess" */ "Route/DpoLayByProcessPage"
  )
)
// export const AsapEligible = lowPriorityLazy(() => import(/* webpackMode: "lazy", webpackChunkName: "asap-eligible" */ 'Route/AsapEligiblePage'));

export const Header = lowPriorityLazy(() =>
  import(
    /* webpackMode: "lazy", webpackChunkName: "header" */ "Component/Header"
  )
)
export const NavigationTabs = lowPriorityLazy(() =>
  import(
    /* webpackMode: "lazy", webpackChunkName: "header" */ "Component/NavigationTabs"
  )
)
export const Footer = lowPriorityLazy(() =>
  import(
    /* webpackMode: "lazy", webpackChunkName: "footer" */ "Component/Footer"
  )
)
export const NewVersionPopup = lowPriorityLazy(() =>
  import(
    /* webpackMode: "lazy", webpackChunkName: "notice" */ "Component/NewVersionPopup"
  )
)
export const NotificationList = lowPriorityLazy(() =>
  import(
    /* webpackMode: "lazy", webpackChunkName: "notice" */ "Component/NotificationList"
  )
)
export const OfflineNotice = lowPriorityLazy(() =>
  import(
    /* webpackMode: "lazy", webpackChunkName: "notice" */ "Component/OfflineNotice"
  )
)
export const CookiePopup = lowPriorityLazy(() =>
  import(
    /* webpackMode: "lazy", webpackChunkName: "notice" */ "Component/CookiePopup"
  )
)
export const DemoNotice = lowPriorityLazy(() =>
  import(
    /* webpackMode: "lazy", webpackChunkName: "notice" */ "Component/DemoNotice"
  )
)
export const SomethingWentWrong = lowPriorityLazy(() =>
  import(
    /* webpackMode: "lazy", webpackChunkName: "something-went-wrong" */ "Route/SomethingWentWrong"
  )
)
export const Breadcrumbs = lowPriorityLazy(() =>
  import(
    /* webpackMode: "lazy", webpackChunkName: "header" */ "Component/Breadcrumbs"
  )
)
export const CityPricing = lowPriorityLazy(() =>
  import(
    /* webpackMode: "lazy", webpackChunkName: "header" */ "Route/CityPricing"
  )
)

import { RouterComponent as SourceRouterComponent } from "SourceComponent/Router/Router.component"

/** @namespace Component/Router/Component/withStoreRegex */
export const withStoreRegex = (path) => window.storeRegexText.concat(path)

/** @namespace Component/Router/Component */
export class RouterComponent extends SourceRouterComponent {
  __construct(props) {
    super.__construct(props)

    // Remove Dublicate Contact Page
    const contactUsPageIndex = this.SWITCH_ITEMS_TYPE.map(
      (el) => el.name
    ).indexOf(RouterSwitchItemType.CONTACT_PAGE)
    if (contactUsPageIndex > -1) {
      this.SWITCH_ITEMS_TYPE.splice(contactUsPageIndex, 1)
    }

    // Switch Wishlist position
    this.SWITCH_ITEMS_TYPE.forEach((value, key) => {
      if (value.name === RouterSwitchItemType.MY_ACCOUNT_WISHLIST) {
        this.SWITCH_ITEMS_TYPE[key].position = 90
      }
    })

    this.SWITCH_ITEMS_TYPE.push(
      {
        component: (
          <Route
            path={withStoreRegex("/mobile-account")}
            exact
            render={() => <MobileAccountPage />}
          />
        ),
        position: 52,
        name: RouterSwitchItemType.MOBILEACCOUNT,
      },
      {
        component: (
          <Route
            path={withStoreRegex("/ordertracking")}
            exact
            render={() => <Ordertracking />}
          />
        ),
        position: 53,
        name: RouterSwitchItemType.ORDERTRACKING,
      },
      {
        component: (
          <Route
            path={withStoreRegex("/store-locator")}
            exact
            render={() => <StoreLocator />}
          />
        ),
        position: 54,
        name: RouterSwitchItemType.STORE_LOCATOR,
      },
      {
        component: (
          <Route
            path={withStoreRegex("/iocheckout/dpo/success")}
            exact
            render={() => <DpoSuccessPage />}
          />
        ),
        position: 55,
        name: RouterSwitchItemType.DOP_SUCCESS,
      },
      {
        component: (
          <Route
            path={withStoreRegex("/dpolayby/redirect/process/:orderId")}
            exact
            render={() => <DpoLayByProcessPage />}
          />
        ),
        position: 56,
        name: RouterSwitchItemType.DOP_LAY_BY_PROCESS,
      },

      // {
      //     component: <Route path={ withStoreRegex('/asap-eligible') } exact render={ () => <AsapEligible /> } />,
      //     position: 57,
      //     name: RouterSwitchItemType.ASAP_ELIGIBLE,
      // },
      {
        component: (
          <Route
            path={withStoreRegex("/city-pricing")}
            render={({ match }) => <CityPricing match={match} />}
          />
        ),
        position: 100,
        name: RouterSwitchItemType.CITY_PRICING,
      },
      {
        component: (
          <Route
            path={withStoreRegex("/contact-us")}
            render={() => <ContactPage />}
          />
        ),
        position: 82,
        name: RouterSwitchItemType.CONTACT_PAGE,
      }
    )
  }

  renderBeforeItemsFallback() {
    const { pathname = appendWithStoreCode("/") } = location

    if (isHomePageUrl(pathname)) {
      return (
        <div
          block='Router'
          elem='HeaderFallbackWrapper'
          mix={{ block: "HomeHeaderFallbackWrapper" }}
        >
          <section
            block='Router'
            elem='HeaderFallback'
            mix={{ block: "HomeHeaderFallback" }}
          >
            {this.renderHeaderFallbackPlaceholder()}
          </section>
        </div>
      )
    }
    return (
      <div block='Router' elem='HeaderFallbackWrapper'>
        <section block='Router' elem='HeaderFallback'>
          {this.renderHeaderFallbackPlaceholder()}
        </section>
        {!isHomePageUrl(pathname) && (
          <section block='Router' elem='BreadcrumbsFallback' />
        )}
      </div>
    )
  }
}

export default RouterComponent
