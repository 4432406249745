Mosaic.addPlugins([require('/var/www/html/src/localmodules/my-app/node_modules/@scandipwa/webpack-i18n-runtime/src/plugin/App.plugin.js')]);
/* eslint-disable */
/* stylelint-disable */
// @ts-nocheck


import {
    AppComponent as SourceAppComponent
} from "SourceComponent/App/App.component";

import { callIoCheckoutHooks } from 'Util/IoCheckout.js';

/** @namespace Component/App/Component */
export class AppComponent extends SourceAppComponent {

    componentDidMount() {

        // IoCheckout hooks call
        callIoCheckoutHooks();  
     }
}

export default AppComponent;


    
