// @ts-nocheck
/* eslint-disable */

import { Field, Mutation, Query } from "@tilework/opus"

/**
 * IoCheckout Query
 * @class IoCheckoutQuery
 * @namespace Query/IoCheckout/Query */
export class IoCheckoutQuery {
  setShippingAddressesOnCart(input) {
    const query = new Mutation("setShippingAddressesOnCart")
    query.addArgument("input", "SetShippingAddressesOnCartInput", input)
    query.addField(this._getCartOutput())
    return query
  }

  _getCartOutput() {
    return new Field("cart", true).addFieldList(this._getCartFieldsOutput())
  }

  _getCartFieldsOutput() {
    return [new Field("email")]
  }

  getPaymentMethodsInstructions() {
    const query = new Mutation("getPaymentMethodInstrucationsElightwalk")
    query.addFieldList(this._getPaymentMethodsInstructionsFields())
    return query
  }
  _getPaymentMethodsInstructionsFields() {
    return [
      new Field("cash_on_delivery"),
      new Field("dpo"),
      new Field("dpolayby"),
      new Field("airtel"),
      new Field("mtn"),
      new Field("zampay"),
    ]
  }

  redirectToDpo(orderNumber) {
    const query = new Mutation("redirectToDpo")
    query.addArgument("orderNumber", "String!", orderNumber)
    query.addFieldList(this._getRedirectToDpoFields())
    return query
  }

  _getRedirectToDpoFields() {
    return [
      new Field("payUrl"),
      new Field("ID"),
      new Field("status"),
      new Field("message"),
    ]
  }

  processDpoPayment(TransID, CCDapproval, PnrID, TransactionToken, CompanyRef) {
    const query = new Mutation("processDpoPayment")
    query.addArgument("TransID", "String!", TransID)
    query.addArgument("CCDapproval", "String", CCDapproval)
    query.addArgument("PnrID", "String!", PnrID)
    query.addArgument("TransactionToken", "String!", TransactionToken)
    query.addArgument("CompanyRef", "String!", CompanyRef)
    query.addFieldList(this._getProcessDpoPaymentFields())
    return query
  }

  _getProcessDpoPaymentFields() {
    return [new Field("status"), new Field("message")]
  }

  processDpoLayByPayment(
    orderNumber,
    TransID,
    CCDapproval,
    PnrID,
    TransactionToken,
    CompanyRef
  ) {
    const query = new Query("processDpoLayByPayment")
    query.addArgument("orderNumber", "String!", orderNumber)
    query.addArgument("TransID", "String!", TransID)
    query.addArgument("CCDapproval", "String", CCDapproval)
    query.addArgument("PnrID", "String!", PnrID)
    query.addArgument("TransactionToken", "String!", TransactionToken)
    query.addArgument("CompanyRef", "String!", CompanyRef)
    query.addFieldList(this._getProcessDpoLayByPaymentFields())
    return query
  }

  _getProcessDpoLayByPaymentFields() {
    return [
      new Field("status"),
      new Field("message"),
      new Field("maskId"),
      new Field("orderNumber"),
      new Field("email"),
    ]
  }

  getStorePaymentRefNumber(cartId) {
    const query = new Query("getStorePaymentRefNumber")
    query.addArgument("cart_id", "String!", cartId)
    return query
  }

  setPaymentMethodOnCart(input) {
    const mutation = new Mutation("setPaymentMethodOnCart")
    mutation.addArgument("input", "SetPaymentMethodOnCartInput", input)
    mutation.addField(this._getCartOutput())
    return mutation
  }
}

export default new IoCheckoutQuery()
