/* eslint-disable */
// @ts-nocheck

import getStore from "Util/Store";

var ioCheckoutElement = null;
var ioCheckoutStore = null;
var cart = {};
var debounsPurchaseTypes;
var shippingAddressFields = [];
var billingAddressFields = [];
var renderComponentFields;
var saveAddressesFunc;
var debounsSaveAddress;
const SHIPPING_ADDRESS_CODE = "ShippingAddress";
const BILLING_ADDRESS_CODE = "BillingAddress";
var collectionOfRegions = []
var currentAddressType = "ShippingAddress"
var regionFieldUpdatedWithReducer = false


export const callIoCheckoutHooks = () => {

  window.IoCheckoutHooks = {
    renderIoCheckoutJS: function (props) {
      const {
        ioCheckoutElement: element,
        cart: ioCheckoutCart,
        iocheckout,
      } = props;

      cart = ioCheckoutCart;
      ioCheckoutStore = iocheckout;
      if (ioCheckoutElement !== null) {
        return;
      }

      ioCheckoutElement = element;

      handleHead();
      const purchaseTypeElementInterval = setInterval(async () => {
        const purchaseTypes = ioCheckoutElement.querySelectorAll(
          'input[name="purchaseType"]'
        );
        if (purchaseTypes.length > 0) {
          clearInterval(purchaseTypeElementInterval);
          await getPurchaseTypesInfo();
          await handlePurchaseTypes();
        }
      }, 10);
      getRegionCityColl();
    },

    beforeRenderShippingAddress: function () {
      let isLogin =
        ioCheckoutStore?._modules?.root?.state?.magentogq?.clientConfig
          ?.isLogin;

      if (!isLogin) {
        return "";
      }

      return renderPurchaseTypeContainer();
    },

    beforeRenderGuestEmail: function () {
      let isLogin =
        ioCheckoutStore?._modules?.root?.state?.magentogq?.clientConfig
          ?.isLogin;

      if (isLogin) {
        return "";
      }

      return renderPurchaseTypeContainer();
    },

    afterSaveBillingAddress: function (props) {
      setPurchaseTypesInfoToCart();
    },

    // Address
    renderInitAddressFields: async function (props) {
      console.log("renderInitAddressFields props", props);
      const { fields, renderComponentFields: renderFields } = props;

      // Fields Assign
      if (fields && fields.length > 0) {
        currentAddressType = fields[0].addressComponent
        if (currentAddressType === SHIPPING_ADDRESS_CODE) {
          shippingAddressFields = fields;
        } else {
          billingAddressFields = fields;
        }
      }

      // Render Component Funcation Assign
      renderComponentFields = renderFields;

      // Manage Telephone Field
      await handleTelephoneInput();

      // Manage City and Region
      const cityFieldInterval = await setInterval(async () => {
        if(collectionOfRegions.length > 0){
          await clearInterval(cityFieldInterval)
          await renderCityAndRegion(fields);
          if(!regionFieldUpdatedWithReducer){
            regionFieldUpdatedWithReducer = true
            await handleSelectedRegionAndCityValueFromHeader(fields)
          }
        }
      }, 1000);
    },

    renderChangeAddressFields: function (props) {
      console.log("renderChangeAddressFields props", props);

      const { fields, type, changableField, renderComponentFields: renderFields } = props;

      currentAddressType = type

      // Fields Assign
      if (type === SHIPPING_ADDRESS_CODE) {
        shippingAddressFields = fields;
      } else {
        billingAddressFields = fields;
      }

      // Render Component Funcation Assign
      renderComponentFields = renderFields;

      if(changableField.id === "region_id"){
        // renderCityAndRegion(fields);
      }
    },

    saveAddressesFields: function (props) {
      console.log("saveAddressesFields props", props)
      const { saveAddresses } = props
      saveAddressesFunc = saveAddresses
    },

    fieldValidationHook: function (props) {
      console.log("fieldValidationHook props", props);
    },

    afterRenderTelephone: function () {
      return `<div>
        <div>
          <input class="intlTelInput-phone" type="tel">
        </div>
        <div class="intlTelInput-phone-error-msg">
          Please enter valid phone number.
        </div>
      </div>`;
    },
  };
};

const handleHead = () => {
  const head = document.head;

  const script = document.createElement("script");
  script.src =
    "https://cdn.jsdelivr.net/npm/intl-tel-input@25.3.0/build/js/intlTelInput.min.js";
  script.async = true;
  head.appendChild(script);
};

const renderPurchaseTypeContainer = () => {
  return `
    <div class="ioc-purchase-wrapper">
      <div class="ioc-col-title uppercase font-semibold">Purchase Type</div>
      <div id="purchase-type-error-msg" class="text-danger mt-10"></div>
      <div class="mt-20">
          <div class="radio-wrapper mb-20">
              <div class="radio-btn"><input id="purchaseTypePersonal" type="radio" name="purchaseType" value="personal"
                      checked> <label for="purchaseTypePersonal"> Personal </label></div>
              <div class="radio-btn"><input id="purchaseTypeBusiness" type="radio" name="purchaseType" value="business">
                  <label for="purchaseTypeBusiness"> Business / Organization</label>
              </div>
          </div>
          <div class="business-options">
              <div class="business-options-fields">
                  <div class="label-top w-full relative p-[1px] z-[1] group floating-label required mb-20">
                      <input id="business_tpin_number" name="business_tpin_number" type="text"
                          placeholder="Business TPIN Numbeber"
                          class="input-text w-full p-3 !bg-white border peer placeholder:opacity-0 focus:outline-none focus:ring-0 h-[46px]">

                      <label for="business_tpin_number"
                          class="input-label block absolute translate-y-[-50%] text-lightblack/[0.7] cursor-auto top-[50%] label-width  left-0 peer-focus:left-[6px] pl-3 z-10 transition-all duration-300 peer-focus:top-[4%] peer-focus:text-[80%] peer-focus:bg-white peer-focus:px-[5px] text-ellipsis overflow-hidden truncate max-w-[70%]">Business
                          / Organization TPIN Number *
                      </label>
                      <div
                          class="absolute floating-label-border top-0 left-0 z-[-1] peer-focus:w-full peer-focus:h-full bg-primary w-0 h-0">
                      </div>
                  </div>
                  <div class="mb-20 ">

                      <div class="label-top w-full relative p-[1px] z-[1] group floating-label required">
                          <input id="business_name" name="business_name" type="text" placeholder="Business Name"
                              class="input-text w-full p-3 !bg-white border peer placeholder:opacity-0 focus:outline-none focus:ring-0 h-[46px]">

                          <label for="business_name"
                              class="input-label block absolute translate-y-[-50%] text-lightblack/[0.7] cursor-auto top-[50%] label-width  left-0 peer-focus:left-[6px] pl-3 z-10 transition-all duration-300 peer-focus:top-[4%] peer-focus:text-[80%] peer-focus:bg-white peer-focus:px-[5px] text-ellipsis overflow-hidden truncate max-w-[70%]">Business / Organization Name *
                          </label>
                          <div
                              class="absolute floating-label-border top-0 left-0 z-[-1] peer-focus:w-full peer-focus:h-full bg-primary w-0 h-0">
                          </div>
                      </div>
                      <div class="mt-10">The name in which the Invoice should be raised</div>
                  </div>
              </div>
          </div>
      </div>
  </div>`;
  // return `<div>
  //   <div>Purchase Type</div>
  //   <div id="purchase-type-error-msg"></div>
  //   <div>
  //     <input type="radio" name="purchaseType" value="personal" checked> Personal
  //     <input type="radio" name="purchaseType" value="business"> Business / Organization
  //   </div>
  //   <div class="business-options">
  //     <div>
  //       <lable>Business / Organization TPIN Number *</label>
  //       <input type="text" name="business_tpin_number" />
  //     </div>
  //     <div>
  //       <lable>Business / Organization Name *</label>
  //       <input type="text" name="business_name" />
  //     </div>
  //   </div>
  // </div>`;
};

const handlePurchaseTypes = () => {
  // Initial Load
  const checkedPurchaseType = ioCheckoutElement.querySelector(
    'input[name="purchaseType"]:checked'
  );
  if (checkedPurchaseType) {
    purchaseTypeShowOrHide(checkedPurchaseType.value);
  }

  // OnChange Event Handle
  const purchaseTypes = ioCheckoutElement.querySelectorAll(
    'input[name="purchaseType"]'
  );
  if (purchaseTypes.length > 0) {
    purchaseTypes.forEach((purchaseType) => {
      purchaseType.addEventListener("change", (e) => {
        const selectedPurchaseType = e.target.value;
        purchaseTypeShowOrHide(selectedPurchaseType);
        setPurchaseTypesInfoToCart();
      });
    });
  }

  ioCheckoutElement
    .querySelector('input[name="business_tpin_number"]')
    ?.addEventListener("input", (e) => {
      setPurchaseTypesInfoToCart();
    });

  ioCheckoutElement
    .querySelector('input[name="business_name"]')
    ?.addEventListener("input", (e) => {
      setPurchaseTypesInfoToCart();
    });
};

const purchaseTypeShowOrHide = (type) => {
  if (type === "personal") {
    ioCheckoutElement.querySelector(".business-options").style.display = "none";
  } else {
    ioCheckoutElement.querySelector(".business-options").style.display =
      "block";
  }
};

const getPurchaseTypesInfo = async () => {
  let url = "/rest/default/V1/purchase_type/" + cart.id;
  const response = await fetch(url, {
    method: "GET",
    headers: getHeader(),
  }).then((response) => {
    return response.json();
  });
  if (response && response.length > 0) {
    const result = response[0];
    if (result.status) {
      let selectedPurchaseType = result.details.purchase_type ?? "personal";
      const allPurchaseTypesElement = ioCheckoutElement.querySelectorAll(
        'input[name="purchaseType"]'
      );
      allPurchaseTypesElement.forEach((purchaseTypeElement) => {
        purchaseTypeElement.checked =
          purchaseTypeElement.value === selectedPurchaseType;
      });
      ioCheckoutElement.querySelector(
        'input[name="business_tpin_number"]'
      ).value = result.details.purchbusiness_tpin_numberase_type;
      ioCheckoutElement.querySelector('input[name="business_name"]').value =
        result.details.business_name;
      purchaseTypeShowOrHide(selectedPurchaseType);
    }
  }
};

const setPurchaseTypesInfoToCart = () => {
  clearTimeout(debounsPurchaseTypes);
  debounsPurchaseTypes = setTimeout(async () => {
    try {
      handleErrorElement(false);

      let businessTpinNumber = "";
      let businessName = "";

      const selectedPurchaseType = ioCheckoutElement.querySelector(
        'input[name="purchaseType"]:checked'
      );
      if (selectedPurchaseType && selectedPurchaseType.value === "business") {
        businessTpinNumber = ioCheckoutElement.querySelector(
          'input[name="business_tpin_number"]'
        ).value;
        businessName = ioCheckoutElement.querySelector(
          'input[name="business_name"]'
        ).value;
      }

      const requestOptions = {
        method: "PUT",
        body: JSON.stringify({
          purchase_type: selectedPurchaseType.value,
          business_tpin_number: businessTpinNumber,
          business_name: businessName,
        }),
        showLoader: false,
        headers: getHeader(),
      };
      let url = "/rest/default/V1/purchase_type/" + cart.id;
      const response = await fetch(url, requestOptions).then((response) => {
        return response.json();
      });
      if (response && response.length > 0) {
        const result = response[0];
        if (result.status) {
          return;
        }
        handleErrorElement(true, result.message);
      }
    } catch (error) {
      handleErrorElement(true, error.message);
    } finally {
      ioCheckoutStore.commit("magentogq/removeAllLoadingArray");
    }
  }, 1000);
};

const handleErrorElement = (error, msg = "") => {
  if (error) {
    ioCheckoutElement.querySelector("#purchase-type-error-msg").innerText = msg;
    ioCheckoutElement.querySelector("#purchase-type-error-msg").display =
      "block";
    return;
  }
  ioCheckoutElement.querySelector("#purchase-type-error-msg").innerText = "";
  ioCheckoutElement.querySelector("#purchase-type-error-msg").display = "none";
};

const getHeader = () => {
  let isLogin = false;
  let customerToken = null;
  if (ioCheckoutStore !== null) {
    isLogin =
      ioCheckoutStore?._modules?.root?.state?.magentogq?.clientConfig?.isLogin;
    customerToken =
      ioCheckoutStore?._modules?.root?.state?.magentogq?.customerToken;
  }

  if (isLogin) {
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${customerToken}`,
    };
  }

  return {
    "Content-Type": "application/json",
    Authorization: "",
  };
};

const handleTelephoneInput = () => {
  handlePhoneField();
  hideTelephoneField();
};

const handlePhoneField = () => {
  const inputs = ioCheckoutElement.querySelectorAll(".intlTelInput-phone");
  if (inputs.length > 0) {
    inputs.forEach((input) => {
      let intlTelInputObject = window?.intlTelInput(input, {
        initialCountry: "zm",
        separateDialCode: true,
        loadUtils: () =>
          import(
            "https://cdn.jsdelivr.net/npm/intl-tel-input@25.3.0/build/js/utils.js"
          ), // for formatting/placeholders etc
      });

      // Closest Error Element
      const closestTelephoneField = input
        .closest("form")
        .querySelector('input[name="telephone"]');
      const errorMsgElement = input
        .closest("form")
        .querySelector(".intlTelInput-phone-error-msg");
      errorMsgElement.style.display = "none";
      input.addEventListener("keyup", (e) => {
        console.log("closestTelephoneField", closestTelephoneField);

        let phoneNumber = intlTelInputObject.getNumber();

        errorMsgElement.style.display = "none";
        if (errorMsgElement) {
          if (!intlTelInputObject.isValidNumber()) {
            errorMsgElement.style.display = "block";
            phoneNumber = "";
            closestTelephoneField.value = "";
          }
        }

        // Compoenent Type
        let type = BILLING_ADDRESS_CODE;

        if (closestTelephoneField) {
          if (
            closestTelephoneField.id &&
            closestTelephoneField.id.indexOf("shipping") > -1
          ) {
            type = SHIPPING_ADDRESS_CODE;
          }
        }

        updateTelephoneField(type, phoneNumber);
      });
    });
  }
};

const updateTelephoneField = async (type, value) => {
  let fields = [];
  if (type === SHIPPING_ADDRESS_CODE) {
    fields = shippingAddressFields;
  } else {
    fields = billingAddressFields;
  }

  fields.forEach((field, key) => {
    if (field.id === "telephone") {
      field.value = value.replace('+', '');
    }
    fields[key] = field;
  });

  // Update Fields
  await renderComponentFields(fields, true);

  // Save Address
  if(value){
    clearTimeout(debounsSaveAddress);
    debounsSaveAddress = setTimeout(() => {
      console.log("updateTelephoneField type", type)
      saveAddressesFunc(type);
    }, 1000);
  }
};

const hideTelephoneField = () => {
  const telephoneElements = ioCheckoutElement.querySelectorAll(
    'input[name="telephone"]'
  );
  if (telephoneElements) {
    telephoneElements.forEach((telephoneElement) => {
      telephoneElement.style.display = 'none'
    });
  }
};

// Get Region City Collection
const getRegionCityColl = async () => {
  const requestOptions = {
    method: "GET",
    showLoader: false,
    headers: getHeader(),
  };
  let url = "/rest/default/V1/rest_region_city/ZM";
  const response = await fetch(url, requestOptions).then((response) => {
    return response.json();
  }).catch((error) => {
    console.log("error", error)
    collectionOfRegions = []
  });
  if (response && response.length > 0) {
    const result = response[0];
    if (result.status) {
      collectionOfRegions = result.data
    }else{
      collectionOfRegions = []
    }
  }
};

const renderCityAndRegion = async (fields) => {

  console.log("renderCityAndRegion fields", fields)

  let selectedRegion = ""
  const filteredFields = fields.filter(field => field.id === "region_id")
  if(filteredFields.length > 0){ selectedRegion = filteredFields[0].value }

  console.log("renderCityAndRegion selectedRegion", selectedRegion)

  let cityOptions = await getCityOptions(selectedRegion)

  console.log("renderCityAndRegion cityOptions", cityOptions)

  fields.forEach((field, key) => {
    if (field.id === "city") {
      field.component = 'SelectOption'
      field.data_type = 'select'
      field.front_type = "dropdown"
      field.isLabelShow = true
      field.isSearch = false
      field.select_options = cityOptions
    }
    fields[key] = field;
  });

  // Update Fields
  await renderComponentFields(fields, true);
}

const getCityOptions = (regionId) => {
  let cityOptions = []
  
  collectionOfRegions.forEach(region => {
    if(region.region_id === regionId){

      cityOptions = region.cities.map((city) => {
        return {
          text: city.city_name,
          value: city.city_name
        }
      })
    }
  })

  return cityOptions
}


const handleSelectedRegionAndCityValueFromHeader = async (fields) => {
  const state = getStore().getState()
  const cityPricingReducer = state.CityPricingReducer
  const cartReducer = state.CartReducer

  const isNotToUpdateValue = cartReducer?.cartTotals?.shipping_addresses.length > 0 ? true : false

  if(!isNotToUpdateValue){
    await fields.forEach((field, key) => {
      if (field.id === "region_id") {
        field.value = cityPricingReducer.savedCookie.region_id
      }
      if (field.id === "city") {
        field.value = cityPricingReducer.savedCookie.city
      }
      fields[key] = field;
    });

    
    setTimeout(() => {
      console.log("handleSelectedRegionAndCityValueFromHeader fields", fields)
      renderComponentFields(fields, true);
    }, 5000);
    // await renderComponentFields(fields, true);
  }
}