// @ts-nocheck
/* eslint-disable */

import {
  UPDATE_ORDER,
  UPDATE_ERROR,
  UPDATE_LOADING,
  UPDATE_STEP,
  UPDATE_STATUS_LIST,
} from "./OrderTracking.type"

/** @namespace Store/OrderTracking/Reducer/getInitialState */
export const getInitialState = () => ({
  statusList: [],
  isLoading: false,
  order: {},
  error: null,
  step: 1,
})

/** @namespace Store/OrderTracking/Reducer/OrderTrackingsReducer */
export const OrderTrackingsReducer = (state = getInitialState(), action) => {
  const { type } = action

  switch (type) {
    case UPDATE_ORDER:
      const { order } = action

      return {
        ...state,
        order: order,
      }

    case UPDATE_ERROR:
      const { error } = action

      return {
        ...state,
        error: error,
      }
    case UPDATE_LOADING:
      const { loading } = action

      return {
        ...state,
        isLoading: loading,
      }
    case UPDATE_STEP:
      const { step } = action

      return {
        ...state,
        step: step,
      }
    case UPDATE_STATUS_LIST:
      const { statusList } = action

      return {
        ...state,
        statusList: statusList,
      }

    default:
      return state
  }
}

export default OrderTrackingsReducer
