// @ts-nocheck
/* eslint-disable */

import { Field, Query, Mutation } from "@tilework/opus"

/**
 * StoreLocator Query
 * @class StoreLocatorQuery
 * @namespace Query/StoreLocator/Query */
export class StoreLocatorQuery {
  gerStoreConfig() {
    const query = new Query("MpStoreLocatorConfig")
    query.addFieldList(this._getStoreConfig())
    return query
  }

  _getStoreConfig() {
    return [
      new Field("KMLinfowindowTemplatePath"),
      new Field("KMLlistTemplatePath"),
      new Field("dataLocations"),
      new Field("defaultLat"),
      new Field("defaultLng"),
      new Field("infowindowTemplatePath"),
      new Field("isDefaultStore"),
      new Field("isFilter"),
      new Field("isFilterRadius"),
      new Field("keyMap"),
      new Field("listTemplatePath"),
      new Field("markerIcon"),
      new Field("router"),
      new Field("urlSuffix"),
      new Field("zoom"),
    ]
  }

  getStoreLocatorsList(filter, pageSize, currentPage) {
    const query = new Query("MpStoreLocatorLocations")

    if (Object.keys(filter).length > 0) {
      query.addArgument("filter", "MpStoreLocatorLocationsFilterInput", filter)
    }
    query.addArgument("pageSize", "Int", pageSize)
    query.addArgument("currentPage", "Int", currentPage)
    query.addField("total_count")
    query.addField(this._getStoreLocatorsItems())
    return query
  }

  _getStoreLocatorsItems() {
    return new Field("items", true).addFieldList(
      this._getStoreLocatorItemsFields()
    )
  }

  _getStoreLocatorItemsFields() {
    return [
      new Field("city"),
      new Field("country"),
      new Field("latitude"),
      new Field("longitude"),
      new Field("location_id"),
      new Field("name"),
      new Field("postal_code"),
      new Field("state_province"),
      new Field("street"),
      new Field("url_key"),
      new Field("images"),
      new Field("phone_one"),
      new Field("phone_two"),
      new Field("email"),
    ]
  }

  SaveLocationElightwalkStoreLocator(input) {
    const mutation = new Mutation("SaveLocationElightwalkStoreLocator")
    mutation.addArgument("input", "ElightwalkStoreLocatorLocationData!", input)
    return mutation
  }
}

export default new StoreLocatorQuery()
