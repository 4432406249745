Mosaic.addPlugins([require('/var/www/html/src/localmodules/my-app/node_modules/@elightwalk/iocheckout/src/plugin/StoreReducer.plugin.tsx'),require('/var/www/html/src/localmodules/my-app/node_modules/@elightwalk/faqs/src/plugin/StoreReducer.plugin.js'),require('/var/www/html/src/localmodules/my-app/node_modules/@elightwalk/infinite_scroll/src/plugin/Reducer.plugin.js')]);
// @ts-nocheck
/* eslint-disable */

import BreadcrumbsReducer from "SourceStore/Breadcrumbs/Breadcrumbs.reducer"
import CartReducer from "SourceStore/Cart/Cart.reducer"
import CategoryReducer from "SourceStore/Category/Category.reducer"
import CheckoutReducer from "SourceStore/Checkout/Checkout.reducer"
import CmsReducer from "SourceStore/Cms/Cms.reducer"
import ConfigReducer from "SourceStore/Config/Config.reducer"
import ContactFormReducer from "SourceStore/ContactForm/ContactForm.reducer"
import MetaReducer from "SourceStore/Meta/Meta.reducer"
import MyAccountReducer from "SourceStore/MyAccount/MyAccount.reducer"
import NavigationReducer from "SourceStore/Navigation/Navigation.reducer"
import NoMatchReducer from "SourceStore/NoMatch/NoMatch.reducer"
import OfflineReducer from "SourceStore/Offline/Offline.reducer"
import OverlayReducer from "SourceStore/Overlay/Overlay.reducer"
import PopupReducer from "SourceStore/Popup/Popup.reducer"
import ProductCompareReducer from "SourceStore/ProductCompare/ProductCompare.reducer"
import ProductListInfoReducer from "SourceStore/ProductListInfo/ProductListInfo.reducer"
import StoreInPickUpReducer from "SourceStore/StoreInPickUp/StoreInPickUp.reducer"
import UrlRewritesReducer from "SourceStore/UrlRewrites/UrlRewrites.reducer"
import WishlistReducer from "SourceStore/Wishlist/Wishlist.reducer"
import MpReviewsReducer from "Store/MpReviews/MpReviews.reducer"
import ProductFbtReducer from "Store/ProductFbt/ProductFbt.reducer"
import CityPricingReducer from "Store/CityPricing/CityPricing.reducer"
import StoreLocatorReducer from "Store/StoreLocator/StoreLocator.reducer"
import SubCategoryReducer from "Store/SubCategory/SubCategory.reducer"
import OrderTrackingReducer from "Store/OrderTracking/OrderTracking.reducer"

/** @namespace Store/Index/getStaticReducers */
export const getStaticReducers = () => ({
  ProductListInfoReducer,
  CartReducer,
  WishlistReducer,
  NoMatchReducer,
  MyAccountReducer,
  NavigationReducer,
  OverlayReducer,
  OfflineReducer,
  PopupReducer,
  UrlRewritesReducer,
  ConfigReducer,
  MetaReducer,
  CheckoutReducer,
  ContactFormReducer,
  ProductCompareReducer,
  StoreInPickUpReducer,
  BreadcrumbsReducer,
  CategoryReducer,
  CmsReducer,
  MpReviewsReducer,
  ProductFbtReducer,
  CityPricingReducer,
  StoreLocatorReducer,
  SubCategoryReducer,
  OrderTrackingReducer,
})
