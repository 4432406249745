// @ts-nocheck
/* eslint-disable */

import { Field, Query, Mutation } from "@tilework/opus"

/**
 * CityPricing Query
 * @class CityPricingQuery
 * @namespace Query/CityPricing/Query */
export class CityPricingQuery {
  getRegions() {
    const query = new Query("getRegionCities")
    query.addField(this._getRegions())
    return query
  }

  _getRegions() {
    return new Field("regions", true).addFieldList(this._getRegionsFields())
  }

  _getRegionsFields() {
    return [new Field("region_id"), new Field("region_name"), this._getCities()]
  }

  _getCities() {
    return new Field("cities", true).addFieldList(this._getCitiesFields())
  }

  _getCitiesFields() {
    return [
      new Field("city_id"),
      new Field("city_name"),
      new Field("region_id"),
      new Field("default_store").addFieldList(this._getDefaultStoreFields()),
    ]
  }

  _getDefaultStoreFields() {
    return [
      new Field("is_default_store"),
      new Field("location_id"),
      new Field("name"),
    ]
  }

  saveCityCookie(input) {
    const mutation = new Mutation("saveCityCookie")
    mutation.addArgument("input", "CityInput!", input)
    mutation.addField("error")
    mutation.addField("message")
    return mutation
  }

  getSavedCityCookie() {
    const query = new Query("getSavedCityCookie")
    query.addField("city")
    query.addField("region_id")
    return query
  }
}

export default new CityPricingQuery()
