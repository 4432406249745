// @ts-nocheck
/* eslint-disable */

import { Field, Query, Mutation } from "@tilework/opus"

/**
 * PurchaseType Query
 * @class PurchaseTypeQuery
 * @namespace Query/PurchaseType/Query */
export class PurchaseTypeQuery {
  getPurchaseType(cartId) {
    const query = new Query("getPurchaseTypeElightwalk")
    query.addArgument("cart_id", "String!", cartId)
    query.addFieldList(this._getPurchaseTypeFields())
    return query
  }

  _getPurchaseTypeFields() {
    return [
      new Field("status"),
      new Field("message"),
      new Field("purchase_type"),
      new Field("business_name"),
      new Field("business_tpin_number"),
    ]
  }

  savePurchaseType(input) {
    const mutation = new Mutation("savePurchaseTypeElightwalk")
    mutation.addArgument("input", "ElightwalkPurchaseTypeDataInput!", input)
    mutation.addFieldList(this._getPurchaseTypeFields())
    return mutation
  }
}

export default new PurchaseTypeQuery()
